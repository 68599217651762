import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BuscarCitaService } from '../../service/buscar-cita.service';
import {PacienteService} from "../../service/paciente.service";

@Component({
  selector: 'app-busqueda-cita',
  templateUrl: './busqueda-cita.component.html',
  styleUrls: ['./busqueda-cita.component.css']
})
export class BusquedaCitaComponent implements OnInit, AfterViewInit {
  citas: any[] = [];
  documento: string = '';
  modalRef?: BsModalRef;
  showTable: boolean = false;


  @ViewChild('form', { static: false }) form: any;
  @ViewChild('documentoInput', { static: false }) documentoInput: ElementRef;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(
    public  service: BuscarCitaService,
    private buscarCitaService: BuscarCitaService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {

    };
  }

  ngAfterViewInit(): void {

    this.dtTrigger.next();
  }

  onSubmit(form: any): void {
    if (form.valid) {
      this.buscarCitas();
    }
  }

  buscarCitas(): void {

    this.buscarCitaService.buscarCita(this.documento).subscribe(
      (data) => {
        this.citas = data;
        console.log('Citas', this.citas);
        this.showTable = true;
        this.dtTrigger.next();
        console.log("Cita", this.citas[0].nombreCompleto);
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }

  openModal(template: TemplateRef<any>): void {
    this.resetForm();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

  resetForm(): void {
    if (this.form) {
      this.form.reset();
    }
    this.documento = '';
    this.citas = [];
    this.showTable = false;


    if (this.documentoInput) {
      setTimeout(() => {
        this.documentoInput.nativeElement.focus();
      }, 0);
    }
  }

  onModelChange() {

    if (this.documento.length > 8) {
      this.documento = this.documento.slice(0, 8);
    }
  }
}
