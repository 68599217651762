import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styleUrls: ['./especialidades.component.css']
})
export class EspecialidadesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
