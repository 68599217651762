import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Citas} from '../model/citas.model';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HistoriaClinica} from "../model/historia-clinica.model";
import {Respuesta} from "../model/respuesta.model";

@Injectable({
  providedIn: 'root'
})
export class CitasService {

  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formData: Citas;
  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();


  // tslint:disable-next-line:typedef
  postCitas(formData: Citas): Observable<Respuesta> {
    // formData.fechaHoraInicio = new Date(formData.fechaHoraInicioString);
    // formData.fechaHoraFin = new Date(formData.fechaHoraFinString);
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.post<Respuesta>(this.rootURL + '/Cita/Insertar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  putCitas(formData: Citas): Observable<Respuesta> {
    // formData.fechaHoraInicio = new Date(formData.fechaHoraInicioString);
    // formData.fechaHoraFin = new Date(formData.fechaHoraFinString);
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.put<Respuesta>(this.rootURL + '/Cita/Actualizar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  getAllTodos(): Observable<Citas[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Cita/Listar', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Citas();
        Object.assign(model, item);
        return model;
      }))
    );
  }
  // ListarConDetalle(): Observable<Citas[]> {
  //
  //   const reqHeader = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: sessionStorage.getItem('jwt')
  //   });
  //
  //   return this.http.get(this.rootURL + '/Cita/ListarConDetalle', {headers: reqHeader}).pipe(
  //     map((data: any[]) => data.map((item: any) => {
  //       const model = new Citas();
  //       Object.assign(model, item);
  //       return model;
  //     }))
  //   );
  // }
  // tslint:disable-next-line:typedef
  deleteCita(id: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.delete(this.rootURL + '/Cita/Eliminar?idCita=' + id, {headers: reqHeader});
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }

  // tslint:disable-next-line:typedef
  getCita(id: number): Observable<Citas> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get<Citas>(this.rootURL + '/Cita/Elemento?idCita=' + id, {headers: reqHeader});
  }

  listarTodosIndicadores(): Observable<Citas[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Cita/IndicadorCita', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Citas();
        Object.assign(model, item);
        return model;
      }))
    );
  }
}
