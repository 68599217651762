
  <div class="row">
    <div class="form-group col-md-10">

    </div>
    <div class="form-group col-md-2 text-center">
      <button type="button" (click)="openModal(template)" class="btn btn-danger btn-block bi bi-search">
        Buscar Historial Citas
      </button>
    </div>
  </div>



<ng-template #template>
  <div class="modal-header">
    <h3 class="col-md-11 fs-3 fs-md-4 fs-lg-5" id="modal-basic-title"><i class="bi bi-journal-text"></i> Buscar Historial Citas</h3>


    <button type="button" class="close pull-right col-md-1" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" class="btn-warning btn">&times;</span>
    </button>
  </div>

  <form #form="ngForm" (ngSubmit)="onSubmit(form)" autocomplete="off">
    <div class="form-group col-md-12">
      <div class="form-row">
        <div class="form-group col-md-4"></div>
        <div class="form-group col-md-3">
          <label>Ingrese N° DNI</label>
          <input
            name="documento"
            [(ngModel)]="documento"
            #documentoInput="ngModel"
            class="form-control"
            required
            pattern="[0-9]*"
            maxlength="8"
            (ngModelChange)="onModelChange()"
            type="text"
            placeholder="-"
          />
          <div *ngIf="documentoInput.dirty || documentoInput.touched" class="invalid-feedback d-block">
            <div *ngIf="documento.length === 0" class="text-danger">Ingrese su DNI</div>
            <div *ngIf="documento.length > 0 && documento.length < 8" class="text-danger">
              Faltan {{ 8 - documento.length }} dígitos.
            </div>
            <div *ngIf="documento.length === 8" class="text-success">
              ✅
            </div>
          </div>
        </div>




        <div class="form-group col-md-1">
          <label style="visibility: hidden;">.</label>
          <button type="submit" [disabled]="form.invalid" class="btn-warning btn btn-block bi bi-search">
          </button>
        </div>
        <div class="form-group col-md-4"></div>
      </div>
    </div>
  </form>



  <div class="modal-body">
    <div class="table-responsive box1">
      <table *ngIf="showTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
             class="table table-striped table-bordered row-border hover">
        <thead class="cabecera_tabla">
        <tr>
          <th>Fecha</th>

          <th>Nombres</th>
          <th>Notas</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let pac of citas">
          <td>{{ pac.fechaCita | date:'dd/MM/yyyy' }}</td>

          <td>{{ pac.nombreCompleto }}</td>
          <td>{{ pac.descripcion }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</ng-template>
