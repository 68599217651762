<form #form="ngForm" (ngSubmit)="form.valid && !submitted && onSubmit(form)">
  <div class="row">

    <div class="col-md-6 col-xl-4">
      <app-busqueda-paciente (messageEvent)="receiveMessage($event)"></app-busqueda-paciente>

      <div class="box1 mt-2">
        <input name="idHistoriaClinica" #idHistoriaClinica="ngModel"
               [(ngModel)]="service.formDataHistoria.idHistoriaClinica"
               class="form-control " hidden>
        <input name="idPaciente" #idPaciente="ngModel"
               [(ngModel)]="service.formDataHistoria.idPaciente"
               value="{{idPacienteHeredado}}"
               class="form-control" hidden>
        <h3><i class="bi bi-clipboard2-pulse"></i> Enfermedad actual</h3>


        <div class="form-row">
          <div class="form-group col-md-12">
            <label><i class="bi bi-question-circle-fill"></i> Motivo de consulta y tiempo de la enfermedad</label>
            <textarea name="motivoConsulta" #motivoConsulta="ngModel"
                      required="required"
                      [(ngModel)]="service.formDataHistoria.motivoConsulta"
                      [ngClass]="{ 'is-invalid': form.submitted && motivoConsulta.invalid||
                  (motivoConsulta.invalid && (motivoConsulta.dirty || motivoConsulta.touched)),
                  'is-valid':motivoConsulta.valid}"
                      class="form-control area_especificar" maxlength="200"></textarea>
          </div>
          <div class="form-group col-md-12">
            <label><i class="bi bi-question-circle-fill"></i> Signos y síntomas principales</label>
            <textarea name="signosSintomasPrincipales" #signosSintomasPrincipales="ngModel"
                      required="required"
                      [(ngModel)]="service.formDataHistoria.signosSintomasPrincipales"
                      [ngClass]="{ 'is-invalid': form.submitted && signosSintomasPrincipales.invalid||
                  (signosSintomasPrincipales.invalid && (signosSintomasPrincipales.dirty || signosSintomasPrincipales.touched)),
                  'is-valid':signosSintomasPrincipales.valid}"
                      class="form-control area_especificar" maxlength="200"></textarea>

          </div>
        </div>
      </div>
      <hr/>
      <div class="box1 box_opciones mb-2">
        <!--      <fieldset class="scheduler-border">-->
        <h3 class="scheduler-border"><i class="bi bi-hand-index-thumb"></i> Opciones</h3>
        <div class="form-row">

          <div class="form-group col-md-4">
            <button [disabled]="submitted" class="btn-warning btn btn-block">
              <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
              Grabar
            </button>

          </div>

          <div class="form-group col-md-4">
            <button  [disabled]="submittedPDF"  type="button" (click)="clickPDF()" class="btn-primary btn btn-block">
              <span *ngIf="submittedPDF" class="spinner-border spinner-border-sm mr-1"></span>
              PDF
            </button>
          </div>

          <div class="form-group col-md-4">
            <button type="button" (click)="clickCancelar(form)" class="btn btn-outline-danger button_opcion btn-block">Cancelar
            </button>
          </div>


        </div>
        <!--      </fieldset>-->
      </div>
    </div>

    <div class=" col-md-6 col-xl-4">


      <div class="box1">
        <!--        <h3><i class="bi bi-card-list"></i> Antecedentes</h3>-->

        <div class="form-row">
          <div class="form-group col-md-12">
            <h3><i class="bi bi-clipboard2-pulse"></i> Antecedentes Familiares</h3>

            <textarea name="antecedentesFamiliares" #antecedentesFamiliares="ngModel"
                      [(ngModel)]="service.formDataHistoria.antecedentesFamiliares"
                      class="form-control area_especificar" maxlength="100"></textarea>
          </div>

          <!--          Antecedentes estomatologicos-->

          <div class="col-md-12">


            <!--          Pregunta 14-->
            <h3><i class="bi bi-clipboard2-pulse"></i> Antecedentes Estomatologicos</h3>
            <div class="form-group col-md-12 box_pregunta"
                 [ngClass]="[service.formDataHistoria.respirarPorBoca===true?'box_sombreado':'',
                 service.formDataHistoria.respirarPorBoca===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Respira por la boca?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input  holaa"
                    type="radio"
                    name="respirarPorBoca"
                    id="respirarPorBoca1"
                    #respirarPorBoca="ngModel"
                    [(ngModel)]="service.formDataHistoria.respirarPorBoca"
                    [value]="true"

                  />
                  <label class="form-check-label" for="respirarPorBoca1"> SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="respirarPorBoca"
                    id="respirarPorBoca2"
                    #respirarPorBoca="ngModel"
                    [(ngModel)]="service.formDataHistoria.respirarPorBoca"
                    [value]="false"

                  />
                  <label class="form-check-label" for="respirarPorBoca2">NO</label>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


            <!--          Pregunta 15-->
            <div class="form-group col-md-12 box_pregunta"
                 [ngClass]="[service.formDataHistoria.dificultadesAbrirBoca===true?'box_sombreado':'',
                 service.formDataHistoria.dificultadesAbrirBoca===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Tiene dificultades para abrir la boca?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="dificultadesAbrirBoca"
                    id="dificultadesAbrirBoca1"
                    #dificultadesAbrirBoca="ngModel"
                    [(ngModel)]="service.formDataHistoria.dificultadesAbrirBoca"
                    [value]="true"

                  />
                  <label class="form-check-label" for="dificultadesAbrirBoca1">SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="dificultadesAbrirBoca"
                    id="dificultadesAbrirBoca2"
                    #dificultadesAbrirBoca="ngModel"

                    [(ngModel)]="service.formDataHistoria.dificultadesAbrirBoca"
                    [value]="false"

                  />
                  <label class="form-check-label" for="dificultadesAbrirBoca2">NO</label>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


            <!--Pregunta 16-->

            <div class="form-group col-md-12 box_pregunta"

                 [ngClass]="[service.formDataHistoria.rechinaDientes===true?'box_sombreado':'',
              service.formDataHistoria.rechinaDientes===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Le rechina los dientes?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="rechinaDientes"
                    id="rechinaDientes1"
                    #rechinaDientes="ngModel"
                    [(ngModel)]="service.formDataHistoria.rechinaDientes"
                    [value]="true"

                  />
                  <label class="form-check-label" for="rechinaDientes1">SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="rechinaDientes"
                    id="rechinaDientes2"
                    #rechinaDientes="ngModel"

                    [(ngModel)]="service.formDataHistoria.rechinaDientes"
                    [value]="false"

                  />
                  <label class="form-check-label" for="rechinaDientes2">NO</label>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


            <!--          Pregunta 17-->

            <div class="form-group col-md-12 box_pregunta"
                 [ngClass]="[service.formDataHistoria.tomaLiquidosOscuros===true?'box_sombreado':'',
              service.formDataHistoria.tomaLiquidosOscuros===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Toma café, té o líquidos oscuros, con frecuencia?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="tomaLiquidosOscuros"
                    id="tomaLiquidosOscuros1"
                    #tomaLiquidosOscuros="ngModel"
                    [(ngModel)]="service.formDataHistoria.tomaLiquidosOscuros"
                    [value]="true"

                  />
                  <label class="form-check-label" for="tomaLiquidosOscuros1">SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="tomaLiquidosOscuros"
                    id="tomaLiquidosOscuros2"
                    #tomaLiquidosOscuros="ngModel"

                    [(ngModel)]="service.formDataHistoria.tomaLiquidosOscuros"
                    [value]="false"

                  />
                  <label class="form-check-label" for="tomaLiquidosOscuros2">NO</label>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


            <!--          Pregunta 18-->

            <div class="form-group col-md-12 box_pregunta"

                 [ngClass]="[service.formDataHistoria.sangranEncias===true?'box_sombreado':'',
              service.formDataHistoria.sangranEncias===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Le sangran las encías cuando se cepilla?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="sangranEncias"
                    id="sangranEncias1"
                    #sangranEncias="ngModel"
                    [(ngModel)]="service.formDataHistoria.sangranEncias"
                    [value]="true"

                  />
                  <label class="form-check-label" for="sangranEncias1">SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="sangranEncias"
                    id="sangranEncias2"
                    #sangranEncias="ngModel"

                    [(ngModel)]="service.formDataHistoria.sangranEncias"
                    [value]="false"

                  />
                  <label class="form-check-label" for="sangranEncias2">NO</label>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>


            <!--          Pregunta 19-->

            <div class="form-group col-md-12 box_pregunta"
                 [ngClass]="[service.formDataHistoria.accesoriosHigieneOral===true?'box_sombreado3':'',
                  service.formDataHistoria.accesoriosHigieneOral===false?'box_sombreado2':'']"
            >
              <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Usa accesorios de higiene oral?</label>
              <div>
                <div class="form-check opcion_1">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="accesoriosHigieneOral"
                    id="accesoriosHigieneOral1"
                    #accesoriosHigieneOral="ngModel"
                    [(ngModel)]="service.formDataHistoria.accesoriosHigieneOral"
                    [value]="true"

                  />
                  <label class="form-check-label" for="accesoriosHigieneOral1">SI</label>
                </div>
                <!-- Default checked radio -->
                <div class="form-check opcion_2">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    name="accesoriosHigieneOral"
                    id="accesoriosHigieneOral2"
                    #accesoriosHigieneOral="ngModel"
                    (change)="clearAccesoriosHigieneOralDesc();"
                    [(ngModel)]="service.formDataHistoria.accesoriosHigieneOral"
                    [value]="false"

                  />
                  <label class="form-check-label" for="accesoriosHigieneOral2">NO</label>
                </div>
                <div class="clearfix"></div>


                <!--inicio espicifique-->
                <div class="col-md-3" [hidden]="!service.formDataHistoria.accesoriosHigieneOral">
                  <label class="mb-2 text_especificar">Especifique:</label>
                </div>
                <div class="col-md-12" [hidden]="!service.formDataHistoria.accesoriosHigieneOral">
                  <input name="accesoriosHigieneOralDesc" #accesoriosHigieneOral="ngModel"
                         [(ngModel)]="service.formDataHistoria.accesoriosHigieneOralDesc"
                         maxlength="100" class="form-control mb-2 box_especificar">
                </div>
              </div>
            </div>
          </div>

          <!--          <hr class = "w-100" >-->


        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-4">

      <div class="box1">
        <!--        Antecedentes personales-->
        <h3><i class="bi bi-clipboard2-pulse"></i> Antecedentes Personales</h3>

        <!--          Pregunta 1-->
        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.bajoTratamientoMedico===true?'box_sombreado':'',
              service.formDataHistoria.bajoTratamientoMedico===false?'box_sombreado2':'']"
        >

          <label class="l_preguntas "><i class="bi bi-caret-right-fill"></i> ¿Está bajo tratamiento médico actualmente?</label>
          <div>
            <div class="form-check opcion_1 ">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="bajoTratamientoMedico"
                id="bajoTratamientoMedico1"

                #bajoTratamientoMedico="ngModel"
                [(ngModel)]="service.formDataHistoria.bajoTratamientoMedico"
                [value]="true"

              />
              <label class="form-check-label">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input "
                type="radio"
                name="bajoTratamientoMedico"
                id="bajoTratamientoMedico2"
                #bajoTratamientoMedico="ngModel"
                (change)="clearBajoTratamientoMedicoDesc();"
                [(ngModel)]="service.formDataHistoria.bajoTratamientoMedico"
                [value]="false"

              />
              <label class="form-check-label" for="bajoTratamientoMedico2">NO</label>
            </div>
            <div class="clearfix"></div>

            <!--inicio espicifique-->
            <div class="col-md-3" [hidden]="!service.formDataHistoria.bajoTratamientoMedico">
              <label class="mb-2 text_especificar">Especifique:</label>
            </div>
            <div class="col-md-12" [hidden]="!service.formDataHistoria.bajoTratamientoMedico">
              <input name="bajoTratamientoMedicoDesc" #bajoTratamientoMedicoDesc="ngModel"
                     [(ngModel)]="service.formDataHistoria.bajoTratamientoMedicoDesc"
                     maxlength="100" class="form-control mb-2 box_especificar">
            </div>
          </div>
        </div>
        <!--          Pregunta 2-->
        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.tomandoMedicamento===true?'box_sombreado':'',
              service.formDataHistoria.tomandoMedicamento===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Está tomando algún medicamento?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input "
                type="radio"
                name="tomandoMedicamento"
                id="tomandoMedicamento1"
                #tomandoMedicamento="ngModel"
                [(ngModel)]="service.formDataHistoria.tomandoMedicamento"
                [value]="true"

              />
              <label class="form-check-label" for="tomandoMedicamento1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="tomandoMedicamento"
                id="tomandoMedicamento2"
                #tomandoMedicamento="ngModel"
                (change)="clearTomandoMedicamentoDesc();"
                [(ngModel)]="service.formDataHistoria.tomandoMedicamento"
                [value]="false"

              />
              <label class="form-check-label" for="tomandoMedicamento2">NO</label>
            </div>
            <div class="clearfix"></div>

            <!--inicio espicifique-->
            <div class="col-md-3" [hidden]="!service.formDataHistoria.tomandoMedicamento">
              <label class="mb-2 text_especificar">Especifique:</label>
            </div>
            <div class="col-md-12" [hidden]="!service.formDataHistoria.tomandoMedicamento">
              <input name="tomandoMedicamentoDesc" #tomandoMedicamento="ngModel"
                     [(ngModel)]="service.formDataHistoria.tomandoMedicamentoDesc"
                     maxlength="100" class="form-control mb-2 box_especificar">
            </div>
          </div>
        </div>


        <!--          Pregunta 3-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.alergico===true?'box_sombreado':'',
              service.formDataHistoria.alergico===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Es alérgico a algún medicamento u otros?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="alergico"
                id="alergico1"
                #alergico="ngModel"
                [(ngModel)]="service.formDataHistoria.alergico"
                [value]="true"

              />
              <label class="form-check-label" for="alergico1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="alergico"
                id="alergico2"
                #alergico="ngModel"
                (change)="clearAlergicoDesc();"
                [(ngModel)]="service.formDataHistoria.alergico"
                [value]="false"

              />
              <label class="form-check-label" for="alergico2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.alergico">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.alergico">
            <input name="alergicoDesc" #alergico="ngModel"
                   [(ngModel)]="service.formDataHistoria.alergicoDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>

        <!--Pregunta 4-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.diabetes===true?'box_sombreado':'',
              service.formDataHistoria.diabetes===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i>¿Diabetes?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="diabetes"
                id="diabetes1"
                #diabetes="ngModel"
                [(ngModel)]="service.formDataHistoria.diabetes"
                [value]="true"

              />
              <label class="form-check-label" for="diabetes1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="diabetes"
                id="diabetes2"
                #diabetes="ngModel"
                (change)="clearDiabetesDesc();"
                [(ngModel)]="service.formDataHistoria.diabetes"
                [value]="false"

              />
              <label class="form-check-label" for="diabetes2">NO</label>
            </div>
            <div class="clearfix"></div>
            <!--inicio espicifique-->
            <div class="col-md-3" [hidden]="!service.formDataHistoria.diabetes">
              <label class="mb-2 text_especificar">Especifique:</label>
            </div>
            <div class="col-md-12" [hidden]="!service.formDataHistoria.diabetes">
              <input name="diabetesDesc" #diabetes="ngModel"
                     [(ngModel)]="service.formDataHistoria.diabetesDesc"
                     maxlength="100" class="form-control mb-2 box_especificar">
            </div>
          </div>
        </div>

        <!--          Pregunta 5-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.hepatitis===true?'box_sombreado':'',
              service.formDataHistoria.hepatitis===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Hepatitis?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hepatitis"
                id="hepatitis1"
                #hepatitis="ngModel"
                [(ngModel)]="service.formDataHistoria.hepatitis"
                [value]="true"

              />
              <label class="form-check-label" for="hepatitis1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hepatitis"
                id="hepatitis2"
                #hepatitis="ngModel"
                (change)="clearHepatitisDesc();"
                [(ngModel)]="service.formDataHistoria.hepatitis"
                [value]="false"

              />
              <label class="form-check-label" for="hepatitis2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.hepatitis">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.hepatitis">
            <input name="hepatitisDesc" #hepatitis="ngModel"
                   [(ngModel)]="service.formDataHistoria.hepatitisDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>

        <!--Pregunta 6-->
        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.hipertenso===true?'box_sombreado':'',
              service.formDataHistoria.hipertenso===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Hipertenso?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hipertenso"
                id="hipertenso1"
                #hipertenso="ngModel"
                [(ngModel)]="service.formDataHistoria.hipertenso"
                [value]="true"

              />
              <label class="form-check-label" for="hipertenso1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hipertenso"
                id="hipertenso2"
                #hipertenso="ngModel"
                (change)="clearHipertensoDesc();"
                [(ngModel)]="service.formDataHistoria.hipertenso"
                [value]="false"

              />
              <label class="form-check-label" for="hipertenso2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.hipertenso">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.hipertenso">
            <input name="hipertensoDesc" #hipertenso="ngModel"
                   [(ngModel)]="service.formDataHistoria.hipertensoDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>

        <!--          Pregunta 7-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.hemofilico===true?'box_sombreado':'',
              service.formDataHistoria.hemofilico===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Hemofílico?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hemofilico"
                id="hemofilico1"
                #hemofilico="ngModel"
                [(ngModel)]="service.formDataHistoria.hemofilico"
                [value]="true"

              />
              <label class="form-check-label" for="hemofilico1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="hemofilico"
                id="hemofilico2"
                #hemofilico="ngModel"
                (change)="clearHemofilicoDesc();"
                [(ngModel)]="service.formDataHistoria.hemofilico"
                [value]="false"

              />
              <label class="form-check-label" for="hemofilico2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.hemofilico">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.hemofilico">
            <input name="hemofilicoDesc" #hemofilico="ngModel"
                   [(ngModel)]="service.formDataHistoria.hemofilicoDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>
        <!--          Pregunta 8-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.epilepsia===true?'box_sombreado':'',
              service.formDataHistoria.epilepsia===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Epilepsia?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="epilepsia"
                id="epilepsia1"
                #epilepsia="ngModel"
                [(ngModel)]="service.formDataHistoria.epilepsia"
                [value]="true"

              />
              <label class="form-check-label" for="epilepsia1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="epilepsia"
                id="epilepsia2"
                #epilepsia="ngModel"
                (change)="clearEpilepsiaDesc();"
                [(ngModel)]="service.formDataHistoria.epilepsia"
                [value]="false"

              />
              <label class="form-check-label" for="epilepsia2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.epilepsia">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.epilepsia">
            <input name="epilepsiaDesc" #epilepsia="ngModel"
                   [(ngModel)]="service.formDataHistoria.epilepsiaDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>
        <!--          Pregunta 9-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.sananHeridas===true?'box_sombreado':'',
              service.formDataHistoria.sananHeridas===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Sus heridas sanan con dificultad?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="sananHeridas"
                id="sananHeridas1"
                #sananHeridas="ngModel"
                [(ngModel)]="service.formDataHistoria.sananHeridas"
                [value]="true"

              />
              <label class="form-check-label" for="sananHeridas1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="sananHeridas"
                id="sananHeridas2"
                #sananHeridas="ngModel"

                [(ngModel)]="service.formDataHistoria.sananHeridas"
                [value]="false"

              />
              <label class="form-check-label" for="sananHeridas2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>

        <!--Pregunta 10-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.tosYgripe===true?'box_sombreado':'',
              service.formDataHistoria.tosYgripe===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Tos o gripe persistentemente en las 2
            últimas semanas?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="tosYgripe"
                id="tosYgripe1"
                #tosYgripe="ngModel"
                [(ngModel)]="service.formDataHistoria.tosYgripe"
                [value]="true"

              />
              <label class="form-check-label" for="tosYgripe1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="tosYgripe"
                id="tosYgripe2"
                #tosYgripe="ngModel"

                [(ngModel)]="service.formDataHistoria.tosYgripe"
                [value]="false"

              />
              <label class="form-check-label" for="tosYgripe2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>

        <!--          Pregunta 11-->

        <div class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.vicioHabito===true?'box_sombreado':'',
              service.formDataHistoria.vicioHabito===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿Tiene algún vicio o hábito?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="vicioHabito"
                id="vicioHabito1"
                #vicioHabito="ngModel"
                [(ngModel)]="service.formDataHistoria.vicioHabito"
                [value]="true"

              />
              <label class="form-check-label" for="vicioHabito1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="vicioHabito"
                id="vicioHabito2"
                #vicioHabito="ngModel"
                (change)="clearVicioHabitoDesc();"
                [(ngModel)]="service.formDataHistoria.vicioHabito"
                [value]="false"

              />
              <label class="form-check-label" for="vicioHabito2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.vicioHabito">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.vicioHabito">
            <input name="vicioHabitoDesc" #vicioHabito="ngModel"
                   [(ngModel)]="service.formDataHistoria.vicioHabitoDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>
        <!--          Pregunta 12-->

        <div *ngIf="idGeneroHeredado!='M'" class="form-group col-md-12 box_pregunta "
             [ngClass]="[service.formDataHistoria.estaEmbarazada===true?'box_sombreado':'',
              service.formDataHistoria.estaEmbarazada===false?'box_sombreado2':'']"
        >
          <label class="l_preguntas"><i class="bi bi-caret-right-fill"></i> ¿En caso de ser mujer, está embarazada?</label>
          <div>
            <div class="form-check opcion_1">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="estaEmbarazada"
                id="estaEmbarazada1"
                #estaEmbarazada="ngModel"
                [(ngModel)]="service.formDataHistoria.estaEmbarazada"
                [value]="true"

              />
              <label class="form-check-label" for="estaEmbarazada1">SI</label>
            </div>
            <!-- Default checked radio -->
            <div class="form-check opcion_2">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                name="estaEmbarazada"
                id="estaEmbarazada2"
                #estaEmbarazada="ngModel"
                (change)="clearEstaEmbarazadaDesc();"
                [(ngModel)]="service.formDataHistoria.estaEmbarazada"
                [value]="false"

              />
              <label class="form-check-label" for="estaEmbarazada2">NO</label>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--inicio espicifique-->
          <div class="col-md-3" [hidden]="!service.formDataHistoria.estaEmbarazada">
            <label class="mb-2 text_especificar">Especifique:</label>
          </div>
          <div class="col-md-12" [hidden]="!service.formDataHistoria.estaEmbarazada">
            <input name="estaEmbarazadaDesc" #estaEmbarazada="ngModel"
                   [(ngModel)]="service.formDataHistoria.estaEmbarazadaDesc"
                   maxlength="100" class="form-control mb-2 box_especificar">
          </div>
        </div>
        <!--          Pregunta 13-->

        <div class="form-group col-md-12 box_pregunta_alt ">
          <label class="l_preguntas"><span><i class="bi bi-exclamation-circle-fill"></i> Tiene algo que declarar al respecto de su salud que no haya sido consignado anteriormente.</span></label>
          <label class="text_especificar"> Especificar: </label>
          <textarea name="declararDetallesDesc" #declararDetallesDesc="ngModel"
                    [(ngModel)]="service.formDataHistoria.declararDetallesDesc"
                    class="form-control mb-2 box_especificar" maxlength="250"></textarea>
        </div>
        <div class="clearfix"></div>

      </div>

    </div>

  </div>
</form>
