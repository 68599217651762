import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {EspecialidadService} from '../../service/especialidad.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-especialidad',
  templateUrl: './especialidad.component.html',
  styleUrls: ['./especialidad.component.css']
})
export class EspecialidadComponent implements OnInit {
  submitted: boolean = false;
  patronNombres = "^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$";
  constructor(public  service: EspecialidadService,
              private toastr: ToastrService,
              private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('es');
  }

  ngOnInit(): void {
    this.resetForm();
  }

  // tslint:disable-next-line:typedef
  resetForm(form?: NgForm) {
    this.submitted = false;
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      idEspecialidad: 0,
      descripcion: '',
      fechaRegistro: new Date(),
      eliminado: 0
    };
  }

  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    this.submitted = true;
    if (form.value.idEspecialidad == null || form.value.idEspecialidad === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }

  }

// tslint:disable-next-line:typedef
  insertRecord(form: NgForm) {
    this.service.postEspecialidad(form.value).subscribe(res => {
      this.toastr.success('Insertado correctamente', 'ESPECIALIDAD');
      this.resetForm(form);
      this.service.filter();
    });
  }

  // tslint:disable-next-line:typedef
  updateRecord(form: NgForm) {
    this.service.putEspecialidad(form.value).subscribe(res => {
      this.toastr.info('Actualizado correctamente', 'ESPECIALIDAD');
      this.resetForm(form);
      this.service.filter();
    });
  }
}


