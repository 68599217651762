import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {MenuComponent} from './menu/menu.component';
import {PacientesComponent} from './pacientes/pacientes.component';
import {AppRoutingModule, routingComponents} from './app-routing.module';
import {DataTablesModule} from 'angular-datatables';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {PacienteComponent} from './pacientes/paciente/paciente.component';
import {PacienteListaComponent} from './pacientes/paciente-lista/paciente-lista.component';
import {FormsModule} from '@angular/forms';
import {PacienteService} from './service/paciente.service';
import {BsDatepickerModule, ModalModule} from 'ngx-bootstrap';
import {LoginComponent} from './login/login.component';
import {LoginService} from './service/login.service';
import {EspecialidadesComponent} from './especialidades/especialidades.component';
import {EspecialidadComponent} from './especialidades/especialidad/especialidad.component';
import {EspecialidadListaComponent} from './especialidades/especialidad-lista/especialidad-lista.component';
import {PersonalgroupComponent} from './personalgroup/personalgroup.component';
import {PersonalComponent} from './personalgroup/personal/personal.component';
import {PersonalListaComponent} from './personalgroup/personal-lista/personal-lista.component';
import {CitasComponent} from './citas/citas.component';
import {HistoriasClinicasComponent} from './historias-clinicas/historias-clinicas.component';
import { FormHistoriasClinicasComponent } from './historias-clinicas/form-historias-clinicas/form-historias-clinicas.component';
import { BusquedaPacienteComponent } from './busqueda-paciente/busqueda-paciente.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { PagoComponent } from './pagos/pago/pago.component';
import { PagoListaComponent } from './pagos/pago-lista/pago-lista.component';
import { PagosComponent } from './pagos/pagos.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeEs from '@angular/common/locales/es';
import {CommonModule, registerLocaleData} from "@angular/common";
import { BuscarPacienteComponent } from './citas/buscar-paciente/buscar-paciente.component';
import {ChartModule} from "angular2-chartjs";
import {PieComponent} from "./dashboard/pie/pie.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import { LineComponent } from './dashboard/line/line.component';
import {AuthGuard} from "./login/AuthGuard";
import { IndicadoresComponent } from './indicadores/indicadores.component';
import {BusquedaCitaComponent} from "./citas/busqueda-cita/busqueda-cita.component";

registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        PacientesComponent,
        routingComponents,
        PacienteComponent,
        PacienteListaComponent,
        LoginComponent,
        EspecialidadesComponent,
        EspecialidadComponent,
        EspecialidadListaComponent,
        PersonalgroupComponent,
        PersonalComponent,
        PersonalListaComponent,
        CitasComponent,
        HistoriasClinicasComponent,
        FormHistoriasClinicasComponent,
        BusquedaPacienteComponent,
        PagoComponent,
        PagoListaComponent,
        PagosComponent,
        CitasComponent,
        BuscarPacienteComponent,
        DashboardComponent,
        PieComponent,
        LineComponent,
        IndicadoresComponent,
        BusquedaCitaComponent
    ],
  imports: [
    BrowserModule,
    CommonModule,
    NgbModule,
    AppRoutingModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    ChartModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDatepickerModule,
    ModalModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [PacienteService, LoginService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}
