import { Injectable } from '@angular/core';
import {Parametrico} from "../model/parametrico.model";
import {BusquedaPaciente} from "../model/busqueda-paciente.model";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Citas} from "../model/citas.model";
import {environment} from "../../environments/environment";


class Cita {
}

@Injectable({
  providedIn: 'root'
})
export class BuscarCitaService {
  rootURL = environment.baseUrl;
  constructor(private http: HttpClient) { }
  formData: Citas;
  buscarCita(documento: string): Observable<Cita[]> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt') || ''
    });

    return this.http.get<any[]>(`${this.rootURL}/Cita/BusquedaCita?documento=${documento}`, { headers: reqHeader }).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Cita();
        Object.assign(model, item);
        return model;
      }))
    );
}


}
