import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Pago} from '../model/pago.model';
import {Observable, Subject} from 'rxjs';
import {map} from "rxjs/operators";
import {PagoDescripcion} from "../model/pago-descripcion.model";
import {Parametrico} from "../model/parametrico.model";

@Injectable({
  providedIn: 'root'
})
export class PagoService {
  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formData: Pago;
  list: Pago[];
  idPacienteH: number = 0;

  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();

  // tslint:disable-next-line:typedef
  postPago(formData: Pago) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.post(this.rootURL + '/Pago/Insertar', formData, {headers: reqHeader});

  }

  // tslint:disable-next-line:typedef
  putPago(formData: Pago) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.put(this.rootURL + '/Pago/Actualizar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  getAllTodos(): Observable<Pago[]>{

    const reqHeader = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL +'/Pago/Listar', {headers: reqHeader}).pipe(
      map((data: any[]) => {
        return data.map((item: any) => {
          let model: Pago;
          model = new Pago();
          Object.assign(model, item);
          return model;
        });
      })
    );

  }

  // tslint:disable-next-line:typedef
  getAllTodosPagoDescripcion(): Observable<PagoDescripcion[]>{

    const reqHeader = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL +'/Pago/ListarPagoDescripcion', {headers: reqHeader}).pipe(
      map((data: any[]) => {
        return data.map((item: any) => {
          let model: PagoDescripcion;
          model = new PagoDescripcion();
          Object.assign(model, item);
          return model;
        });
      })
    );

  }
  obtenerPagosPorPaciente(): Observable<PagoDescripcion[]>{

    const reqHeader = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL +'/Pago/ListarPagoPorPaciente?idPaciente='+this.idPacienteH, {headers: reqHeader}).pipe(
      map((data: any[]) => {
        return data.map((item: any) => {
          let model: PagoDescripcion;
          model = new PagoDescripcion();
          Object.assign(model, item);
          return model;
        });
      })
    );

  }

  deletePago (id: number){

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
      return this.http.delete(this.rootURL+ '/Pago/Eliminar?idPago=' + id, {headers: reqHeader});
    }

  listen():Observable<any>{
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }
}

