<div class="box1">
  <h3><i class="bi bi-card-list"></i> Especialidad</h3>
  <form #form="ngForm" (ngSubmit)="form.valid && !submitted && onSubmit(form)" autocomplete="off">
    <div class="form-row boxModulo">
      <div class="form-group col-md-4" hidden>
        <label>Código</label>
        <input name="idEspecialidad" #idEspecialidad="ngModel" [(ngModel)]="service.formData.idEspecialidad"
               class="form-control" readonly>
      </div>
      <div class="form-group col-md-12">
        <label>Descripción</label>
        <input type="text" class name="descripcion"
               #descripcion="ngModel"
               [(ngModel)]="service.formData.descripcion"
               required
               [ngClass]="{ 'is-invalid': form.submitted && descripcion.invalid||
                  (descripcion.invalid && (descripcion.dirty || descripcion.touched)),
                  'is-valid':descripcion.valid}"
               maxlength="200"
               [pattern]="patronNombres"
               class="form-control"
               oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)"
             class="invalid-feedback">
          <div *ngIf="descripcion.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="descripcion.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="descripcion.errors?.['maxlength']">
            Caracteres máx: 200.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <button type="button" (click)="form.reset()" class="btn btn-info btn-block">Nuevo Registro
        </button>
      </div>
      <div class="form-group col-md-4">
        <button [disabled]="submitted " class="btn-warning btn btn-block">
          <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
          Grabar
        </button>
      </div>
      <div class="form-group col-md-4">
        <button type="button" (click)="form.reset()" class="btn btn-danger btn-block">Cancelar</button>
      </div>
    </div>

  </form>
</div>
