import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Parametrico} from "../model/parametrico.model";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Concepto} from '../model/concepto.model';

@Injectable({
  providedIn: 'root'
})
export class ParametricoService {
  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) { }
  listaConceptoCita: Parametrico[];

  getListaConceptosCita(): Observable<Parametrico[]>
  {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get(this.rootURL + '/Parametrico/ListarParametrico?grupo=CITA_DETALLE' , {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Parametrico();
        Object.assign(model, item);
        return model;
      }))
    );
  }

  formData: Concepto;
  list: Concepto[];

  // tslint:disable-next-line:typedef
  getListarConceptos(): Observable<Concepto[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Parametrico/ListarConcepto', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Concepto();
        Object.assign(model, item);
        return model;
      }))
    );
  }
}
