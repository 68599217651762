<div class="row">
  <div class="col-md-12">
    <div class="box1">
      <h3><i class="bi bi-person-fill"></i> Datos</h3>
      <!--      <div class="form-row boxModulo">-->
      <!--        <button type="button" class="btn btn-primary btn-block" (click)="triggerModal(modalData)">Buscar</button>-->
      <!--      </div>-->


      <form #form="ngForm" autocomplete="off">
        <div class="form-row boxModulo">
          <div class="form-group col-md-4">
            <label>Cód.Usuario.</label>
            <input name="codigo" #codigo="ngModel" [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.codigo"
                   class="form-control input_readonly" readonly>
          </div>
          <div class="form-group col-md-4">
            <label>Tipo doc.</label>
            <input name="tipoDocumento" #tipoDocumento="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.tipoDocumento"
                   class="form-control input_readonly" readonly>
          </div>
          <div class="form-group col-md-4">
            <label>Nro</label><!---->
            <input name="nroDocumento" #nroDocumento="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.nroDocumento"
                   class="form-control input_readonly" readonly>
          </div>
          <div class="form-group col-md-12">
            <label>Nombres.</label>
            <input name="nombres" #nombres="ngModel" [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.nombres"
                   class="form-control input_readonly" readonly>
          </div>
          <div class="form-group col-md-6">
            <label>Primer Apellido</label>
            <input name="primerApellido" #primerApellido="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.primerApellido"
                   class="form-control input_readonly" readonly>
          </div>
          <div class="form-group col-md-6">
            <label>Segundo Apellido</label>
            <input name="segundoApellido" #segundoApellido="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formDataBuscado.segundoApellido"
                   class="form-control input_readonly" readonly>
          </div>



        </div>
      </form>
      <!--      <div class="form-row boxModulo">-->
      <button type="button" class="btn_buscar btn btn-warning btn-block col-md-12" (click)="triggerModal(modalData)"><i
        class="bi bi-search"></i> Buscar
      </button>
      <!--      </div>-->
    </div>
  </div>
</div>


<ng-template #modalData let-modal>
  <div class="modal-content ">
    <div class="modal-header ">
      <h3 class="col-md-10 " id="modal-basic-title"><i class="bi bi-journal-text"></i> Buscar Paciente</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" class="btn-warning btn ">×</span>
      </button>
    </div>


    <div class="modal-body ">
      <form #formBusqueda="ngForm" (ngSubmit)="formBusqueda.valid && !submitted && onSubmit(formBusqueda)" autocomplete="off">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Elija Tipo Búsq.:<sup>*</sup></label>
            <select #id="ngModel" name="id" [(ngModel)]="serviceBusquedaPaciente.formData.id"
                    required="required" class="form-control "
                    [ngClass]="{ 'is-invalid': formBusqueda.submitted && id.value==0 }" required
            >
              <option value=0 class="text-primary font-weight-bolder"></option>
              <option [ngValue]="objeto.id"
                      *ngFor="let objeto of listaParametrico">{{objeto.descripcion}}</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Descripción<sup>*</sup></label>
            <input name="descripcion" #descripcion="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formData.descripcion"
                   [pattern]="patronNumerosLetras"
                   [ngClass]="{ 'is-invalid': formBusqueda.submitted && descripcion.invalid||
                  (descripcion.invalid && (descripcion.dirty || descripcion.touched)),
                  'is-valid':descripcion.valid}"
                   minlength="4"
                   maxlength="15"
                   required
                   class="form-control">
            <div *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)"
                 class="invalid-feedback">
              <div *ngIf="descripcion.errors?.['required']">
                Campo requerido.
              </div>
              <div *ngIf="descripcion.errors?.['minlength']">
                Caracteres mín: 4.
              </div>
              <div *ngIf="descripcion.errors?.['maxlength']">
                Caracteres máx: 15.
              </div>
              <div *ngIf="descripcion.errors?.['pattern']">
                Escriba números y/o letras.
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <sup>(*) Estos campos son obligatorios</sup>
          </div>
          <div class="form-group col-md-12">
            <button [disabled]="submitted " class="btn-warning btn btn-block">
              <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
              Buscar
            </button>
          </div>
        </div>
      </form>

      <div *ngIf="vertabla" class="table-responsive box1 ">

        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
               class="table table-striped table-bordered  row-border hover">
          <thead class="cabecera_tabla">
          <tr>
            <th>Código</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Tipo Doc.</th>
            <th>Nro Doc.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let pac of datos">
            <td (click)="populateForm(pac)">{{pac.codigo}}</td>
            <td (click)="populateForm(pac)">{{pac.nombres}}</td>
            <td (click)="populateForm(pac)">{{pac.primerApellido}} {{pac.segundoApellido}}</td>
            <td (click)="populateForm(pac)">{{pac.tipoDocumento}}</td>
            <td (click)="populateForm(pac)">{{pac.nroDocumento}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!vertabla">
        <div class="alert alert-info text-center" role="alert">
          No existe coincidencias con su busqueda!
        </div>
      </div>

    </div>
    <!--  <div class="modal-footer">-->
    <!--    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Cancelar</button>-->
    <!--  </div>-->
  </div>
</ng-template>

