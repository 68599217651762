import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-personalgroup',
  templateUrl: './personalgroup.component.html',
  styleUrls: ['./personalgroup.component.css']
})
export class PersonalgroupComponent implements OnInit {
  data: any;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
  }

}
