export class Genero {


  constructor(idGenero: string, descripcion: string) {
    this.idGenero = idGenero;
    this.descripcion = descripcion;
  }

  idGenero: string;
  descripcion: string;
}
