import { Component, OnInit } from '@angular/core';

import {Concepto} from '../../model/concepto.model';
import {PagoService} from '../../service/pago.service';
import {ToastrService} from 'ngx-toastr';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {NgForm} from '@angular/forms';
import {ParametricoService} from '../../service/parametrico.service';
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EnviarPaciente} from "../../model/personalizado/enviar-paciente.model";

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']

})
export class PagoComponent implements OnInit {
  submitted: boolean = false;
  patronNumeros = "^[0-9.]*";
  listaConcepto: Concepto[];
  closeModal: String;
  private idPacienteHeredado: number;

  constructor(public service: PagoService,
              public serviceParametrico: ParametricoService,
              public ListarPagoPorPaciente: PagoService,
              private toastr: ToastrService,
              private bsLocalService: BsLocaleService,
              private modalService: NgbModal
  ) {
    this.bsLocalService.use('es');
  }

  ngOnInit(): void {
    this.resetForm();
    this.getConcepto();
  }

// tslint:disable-next-line:typedef
  resetForm(form ?: NgForm) {
    this.submitted = false;
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      idPago: null,
      idPaciente: this.idPacienteHeredado,
      monto: null,
      idConcepto: 0,
      fechaPago: new Date(),
      eliminado: 0,
    };
  }

  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    if (this.service.formData.idConcepto == 0) {
      return false;
    }
    if(this.idPacienteHeredado==null || this.idPacienteHeredado == 0)
    {
      this.toastr.warning('Seleccione un paciente', 'PAGO');
      this.submitted = false;
      return false;
    }
    form.value.idPaciente = this.idPacienteHeredado;
    this.submitted = true;
    if (form.value.idPago == null || form.value.idPago === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }
  }

  //  tslint:disable-next-line:typedef
  getConcepto() {
    this.serviceParametrico.getListarConceptos().subscribe(
      res => this.listaConcepto = res
    );
  }

  // tslint:disable-next-line:typedef
  insertRecord(form: NgForm) {
    this.service.postPago(form.value).subscribe( res => {
      this.toastr.success('Insertado correctamente', 'PAGO');
      this.resetForm(form);
      this.service.filter();
    });
  }

  // tslint:disable-next-line:typedef
  dtOptions: any;
  dtTrigger: any;
  datos: any;
  updateRecord(form: NgForm){
    this.service.putPago(form.value).subscribe(  res => {
      this.toastr.info('Actualizado correctamente', 'PAGO');
      this.resetForm(form);
      this.service.filter();
    });
  }

  populateForm(item: any) {

  }

  receiveMessage($event: EnviarPaciente) {
    this.idPacienteHeredado = $event.idPaciente;
    //this.resetForm();
    this.service.formData.idPaciente = this.idPacienteHeredado;
    this.service.idPacienteH = this.idPacienteHeredado;
    this.service.filter();
  }

  clickNuevo(form: NgForm) {
    form.reset();
    window.location.reload();
  }
}
