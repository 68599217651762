import {Component, OnInit} from '@angular/core';
import {DashboardService} from "../../service/dashboard.service";
import {DashboardLine} from "../../model/dashboard-line.model";
import {HelperService} from "../../service/helper.service";

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit {
  diasSemana: string[] = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
  colores: string[] = [
    "#33AED3",
    "#A757DD",
    "#FFBF46",
    "#ffee93",
    "#F6E9FF",
    "#F55FA5",
    "#0081A7",
    "#8D4BFF",
    "#FF9940",
    "#F0C9DB",
    "#33AED3",
    "#64E0E9",
    "#7027A0",
    "#1DB9C3",
    "#A169FF",
    "#A757DD",
    "#F56FAD",
    "#F77E17"
  ];
  diasNumero: number[] = [];
  diasOrdenado: string[] = [];

  type = 'bar';
  data = {
    labels: this.diasOrdenado,
    datasets: []
  };
  options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  };

  dashLine: DashboardLine[];
  listaUnicaEspecialidad: string[] = [];
  arrayPadre: any = [];

  constructor(public dashService: DashboardService, private helper: HelperService,) {
  }

  ngOnInit(): void {
    this.helper.data.subscribe(res => {
      if(res)
      {
        this.rellenar();
      }
    })
    for (let i = 0; i < 7; i++) {
      this.diasOrdenado.push(this.diasSemana[this.addDays(i).getDay()]);
      this.diasNumero.push(this.addDays(i).getDate());
    }

  }

  private rellenar() {
    this.dashService.getLine().subscribe(res => {
      this.dashLine = res;
      this.dashLine.forEach(item => {
        if (!this.listaUnicaEspecialidad.includes(item.especialidad)) {
          this.listaUnicaEspecialidad.push(item.especialidad);
        }
      });


      let i = 0;
      this.listaUnicaEspecialidad.forEach(nEspecialidad => {
        const valor: any = {};
        const arrayContarDias: number[] = [];
        this.diasNumero.forEach(nDia => {
          const dashL = this.dashLine.filter(x => x.dia === nDia && x.especialidad == nEspecialidad)[0];
          if (dashL) {
            arrayContarDias.push(dashL.contar);
          } else {
            arrayContarDias.push(0);
          }
        });
        this.arrayPadre.push(
          {
            label: nEspecialidad,
            data: arrayContarDias,
            backgroundColor: this.colores[i]
          }
        )
        i++;
      });

      this.data = {
        labels: this.diasOrdenado,
        datasets: this.arrayPadre
      };

    })

  }

  addDays(days: number): Date {
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }

}
