<nav class="navbar navbar-dark  navbar-expand-lg barra_principal">
  <img class="logo_principal" src="assets/images/logo.png" >
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><i class="bi bi-house-fill"></i> Inicio
          <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/pacientes" routerLinkActive="active"><i class="bi bi-person-square"></i> Paciente</a>
      </li>
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/citas" routerLinkActive="active"><i class="bi bi-check-square"></i> Citas</a>
      </li>

      <!--<li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/historias" routerLinkActive="active"><i class="bi bi-clock-history"></i> Historias Clinicas</a>
      </li>-->
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/especialidades" routerLinkActive="active"><i class="bi bi-card-list"></i> Especialidades</a>
      </li>
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/personal" routerLinkActive="active"><i class="bi bi-person-lines-fill"></i> Personal</a>
      </li>
      <li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/indicadores" routerLinkActive="active"><i class="bi bi-palette2"></i> Indicadores</a>
      </li>
      <!--<li class="nav-item">
        <a [hidden]="!isAuthenticated" class="nav-link menu_seleccion" routerLink="/pagos" routerLinkActive="active"><i class="bi bi-cash-coin"></i> Pagos</a>
      </li>-->
    </ul>
    <ul class="navbar-nav" [hidden]="isAuthenticated">
      <li class="nav-item">
        <a [hidden]="isAuthenticated" class="nav-link" routerLink="/login" routerLinkActive="active"><i class="bi bi-box-arrow-in-down-right"></i> Ingresar</a>
      </li>
    </ul>
    <ul class="nav navbar-nav ml-auto" [hidden]="!isAuthenticated">
      <li class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle nombre_usuario" data-toggle="dropdown"><img class="foto_usuario" src="assets/images/user_admin.jpg">Admin</a>
        <div class="dropdown-menu dropdown-menu-right box_menuOpcion ">
          <a href="#" (click)="cerrarSesion()" class="dropdown-item usuario_opcion">Salir</a>
        </div>
      </li>
    </ul>

  </div>


</nav>
