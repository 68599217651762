import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {NgForm} from "@angular/forms";
import {BusquedaPacienteService} from "../../service/busqueda-paciente.service";
import {BusquedaPaciente} from "../../model/busqueda-paciente.model";
import {Parametrico} from "../../model/parametrico.model";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {PersonaFiltrada} from "../../model/personalizado/persona-filtrada.model";

@Component({
  selector: 'app-buscar-paciente',
  templateUrl: './buscar-paciente.component.html',
  styleUrls: ['./buscar-paciente.component.css']
})
export class BuscarPacienteComponent implements OnInit {
  personaFiltrada: PersonaFiltrada;
  closeModal: string;
  listaParametrico: Parametrico[];
  modal : NgbModalRef;


  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  datos: BusquedaPaciente[];


  idPacienteAHeredar: number = 0;
  @Output() messageEvent = new EventEmitter<PersonaFiltrada>();
  @Input() item = false;

  constructor(private modalService2: NgbModal,
              public serviceBusquedaPaciente: BusquedaPacienteService) {
    this.personaFiltrada = new PersonaFiltrada();
  }

  ngOnInit(): void {
    this.getListarTipoBusquedaPaciente();
    this.resetForm();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  // tslint:disable-next-line:typedef
  getListarTipoBusquedaPaciente() {
    this.serviceBusquedaPaciente.getListarTipoBusquedaPaciente().subscribe(res => this.listaParametrico = res);
  }
  // tslint:disable-next-line:typedef
  resetForm(form ?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.serviceBusquedaPaciente.formData = {
      id: null,
      descripcion: ''
    }
    this.serviceBusquedaPaciente.formDataBuscado = {
      idPaciente: 0,
      codigo: '',
      nombres: '',
      primerApellido: '',
      segundoApellido: '',
      idTipoDocumento: 0,
      tipoDocumento: '',
      nroDocumento: '',
      celular:'',
      genero: ''
    }
  }

  // open(modalBuscarPaciente) {
  //   this.resetForm();
  //   this.datos = null;
  //   this.modal = this.modalService2.open(modalBuscarPaciente, { centered: true, backdropClass: 'light-blue-backdrop' })
  //   this.modal.result.then((e) => {
  //     console.log("close");
  //   });
  // }
  // cerrar() {
  //   console.log("cerrar");
  //   this.modal.close();
  //   this.personaFiltrada.esPacienteRegistrado = false;
  //   this.messageEvent.emit(this.personaFiltrada);
  // }

  // tslint:disable-next-line:typedef
  cargarModal(modalBuscarPaciente) {
    this.resetForm();
    this.datos = null;
    this.modal = this.modalService2.open(modalBuscarPaciente, { centered: true, backdropClass: 'light-blue-backdrop' });
     this.modal.result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.cerrarModal(res)}`;
    });
  }

  private cerrarModal(reason: any): string {
    this.personaFiltrada.esPacienteRegistrado = false;
    this.messageEvent.emit(this.personaFiltrada);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

  // triggerModal2(content) {
  //   this.modalService2.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
  //     this.closeModal = `Closed with: ${res}`;
  //   }, (res) => {
  //     this.closeModal = `Dismissed ${this.getDismissReason2(res)}`;
  //   });
  // }
  //
  // private getDismissReason2(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  onSubmit(form: NgForm) {
    this.cargarTabla(form);

    // this.dtTrigger.next();
    //this.rerender();
  }

  cargarTabla(form: NgForm) {
    this.serviceBusquedaPaciente.getListarBusquedaPaciente(form.value).subscribe(res => {
      // this.toastr.info('Actualizado correctamente', 'ESPECIALIDAD');
      this.datos = res;
      // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // dtInstance.destroy();
      //  this.dtTrigger.next();
      //   });
    });
  }

  populateForm(bp: BusquedaPaciente) {
    this.serviceBusquedaPaciente.formDataBuscado = Object.assign({}, bp);
    this.personaFiltrada.nombreCompleto = bp.nombres + ' ' + bp.primerApellido + ' ' + bp.segundoApellido;
    this.personaFiltrada.documento = bp.nroDocumento;
    this.personaFiltrada.celular = bp.celular;
    this.personaFiltrada.idPaciente = bp.idPaciente;
     this.messageEvent.emit(this.personaFiltrada);
    // this.messageEvent2.emit(this.esPacienteRegistrado);
  //  console.log( this.modalService2.dismissAll());
    // @ts-ignore
    this.modal.close();
   ;
  }


  changePacienteRegistrado(modalBuscarPaciente) {
    this.messageEvent.emit(this.personaFiltrada);
    if (this.personaFiltrada.esPacienteRegistrado)
    {
      this.cargarModal(modalBuscarPaciente);
    }
  }
}
