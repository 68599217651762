import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

import { PacienteService } from '../../service/paciente.service';
import { TipoDocumento } from '../../model/tipo-documento.model';
import { Genero } from '../../model/genero.model';
import { EstadoCivil } from '../../model/estado-civil.model';
import { Paciente } from '../../model/paciente.model';

defineLocale('es', esLocale);

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css']
})
export class PacienteComponent implements OnInit {
  submitted: boolean = false;
  listaTipoDocumento: TipoDocumento[] = [];
  listaGenero: Genero[] = [];
  listaEstadoCivil: EstadoCivil[] = [];
  bsRangeValue: Date[];
  patronNumerosLetras = "^[0-9a-zA-Z]*";
  patronNumeros = "^[0-9]*";
  patronNombres = "^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$";
  patronEmail = "^[a-z]+[a-z0-9._-]+@[a-z]+\\.[a-z.]{2,5}$";

  constructor(
    public service: PacienteService,
    private toastr: ToastrService,
    private bsLocaleService: BsLocaleService
  ) {
    this.bsLocaleService.use('es');
  }

  ngOnInit(): void {
    this.resetForm();
    this.initializeLists();
    this.setDefaultTipoDocumento();
  }

  private initializeLists(): void {
    this.listaTipoDocumento.push(new TipoDocumento(1, 'DNI', 0));
    // Puedes agregar más tipos de documentos si es necesario

    this.listaGenero.push(new Genero('M', 'Masculino'));
    this.listaGenero.push(new Genero('F', 'Femenino'));
    // Puedes agregar más géneros si es necesario

    this.listaEstadoCivil.push(new EstadoCivil(1, 'Soltero(a)', 0));
    this.listaEstadoCivil.push(new EstadoCivil(2, 'Casado(a)', 0));
    this.listaEstadoCivil.push(new EstadoCivil(3, 'Viudo(a)', 0));
    this.listaEstadoCivil.push(new EstadoCivil(4, 'Divorciado(a)', 0));
  }

  private setDefaultTipoDocumento(): void {
    if (this.listaTipoDocumento && this.listaTipoDocumento.length > 0) {
      this.service.formData.idTipoDocumento = this.listaTipoDocumento[0].idTipoDocumento;
    }
  }

  resetForm(form?: NgForm): void {
    this.submitted = false;
    if (form) {
      form.resetForm();
    }
    this.service.formData = {
      idPaciente: 0,
      codigo: '',
      idTipoDocumento: 0,
      nroDocumento: '',
      nombres: '',
      primerApellido: '',
      segundoApellido: '',
      fechaNacimiento: null,
      celular: '',
      email: '',
      genero: '',
      idEstadoCivil: 0,
      direccion: '',
      celularContacto: '',
      nombresContacto: '',
      apellidosContacto: '',
      documentoContacto: '',
      idParentesco: null,
      fechaRegistro: new Date(),
      eliminado: 0
    };
  }

  onSubmit(form: NgForm): void {
    if (!this.isFormValid(form)) {
      return;
    }
    this.submitted = true;
    if (form.value.idPaciente == null || form.value.idPaciente === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }
  }

  private isFormValid(form: NgForm): boolean {
    const isDateValid = form.value.fechaNacimiento instanceof Date && !isNaN(form.value.fechaNacimiento);
    if (!isDateValid) {
      return false;
    }

    const formData = this.service.formData;
    if (
      formData.idTipoDocumento === 0 ||
      formData.nroDocumento === '' ||
      formData.nombres === '' ||
      formData.primerApellido === '' ||
      formData.genero === '' ||
      formData.idEstadoCivil === 0 ||
      formData.fechaNacimiento == null ||
      formData.direccion === '' ||
      formData.celular === '' ||
      formData.email === ''
    ) {
      return false;
    }
    return true;
  }

  private insertRecord(form: NgForm): void {
    this.service.postPaciente(form.value).subscribe(res => {
      if (res.idRespuesta === 200) {
        this.toastr.success('Insertado correctamente', 'PACIENTE');
        this.resetForm(form);
        this.service.filter();  // Notifica el cambio
      } else {
        this.submitted = false;
        this.toastr.warning(res.mensaje, 'CITA');
      }
    });
  }

  private updateRecord(form: NgForm): void {
    this.service.putPaciente(form.value).subscribe(res => {
      if (res.idRespuesta === 200) {
        this.toastr.info('Actualizado correctamente', 'PACIENTE');
        this.resetForm(form);
        this.service.filter();  // Notifica el cambio
      } else {
        this.toastr.warning(res.mensaje, 'CITA');
        this.submitted = false;
      }
    });
  }
}
