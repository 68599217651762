<div class="container-fluid">
  <h1 class="display-4 text-center">Registro de Pago </h1>
  <hr>
  <div class="row">
    <div class="col-md-5">
      <app-pago></app-pago>
    </div>
    <div class="col-md-7">
      <app-pago-lista></app-pago-lista>
    </div>
  </div>
  </div>
