import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {DataTableDirective} from "angular-datatables";
import {Parametrico} from '../model/parametrico.model';
import {BusquedaPacienteService} from '../service/busqueda-paciente.service';
import {BusquedaPaciente} from '../model/busqueda-paciente.model';
import {Subject} from 'rxjs';
import {EnviarPaciente} from "../model/personalizado/enviar-paciente.model";

@Component({
  selector: 'app-busqueda-paciente',
  templateUrl: './busqueda-paciente.component.html',
  styleUrls: ['./busqueda-paciente.component.css']
})
export class BusquedaPacienteComponent implements OnInit {
  closeModal: string;
  listaParametrico: Parametrico[];
  vertabla: boolean = true;
  patronNumerosLetras = "^[0-9a-zA-Z]*";
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  datos: BusquedaPaciente[];


  idPacienteAHeredar: number = 0;

  @Output() messageEvent = new EventEmitter<EnviarPaciente>();

  constructor(private modalService: NgbModal,
              public serviceBusquedaPaciente: BusquedaPacienteService,
              private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.getListarTipoBusquedaPaciente();
    this.resetForm();
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  // tslint:disable-next-line:typedef
  resetForm(form ?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.serviceBusquedaPaciente.formData = {
      id: 0,
      descripcion: ''
    }
    this.serviceBusquedaPaciente.formDataBuscado = {
      idPaciente: 0,
      codigo: '',
      nombres: '',
      primerApellido: '',
      segundoApellido: '',
      idTipoDocumento: 0,
      tipoDocumento: '',
      nroDocumento: '',
      celular: '',
      genero: ''
    }
  }

  inicializar() {

  }

  // tslint:disable-next-line:typedef
  triggerModal(content) {
    this.resetForm();
    this.datos = null;
    this.vertabla = true;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

  // tslint:disable-next-line:typedef
  submitted: boolean = false;
  getListarTipoBusquedaPaciente() {
    this.serviceBusquedaPaciente.getListarTipoBusquedaPaciente().subscribe(res => this.listaParametrico = res);
  }

  onSubmit(formBusqueda: NgForm) {
    this.submitted = true;
    this.cargarTabla(formBusqueda);
    this.submitted = false;

    // this.dtTrigger.next();
    //this.rerender();
  }

  cargarTabla(form: NgForm) {
    this.serviceBusquedaPaciente.getListarBusquedaPaciente(form.value).subscribe(res => {
      this.datos = res;
      if (this.datos.length == 0) {
        this.vertabla = false;
      } else {
        this.vertabla = true;
      }
    });
  }

  populateForm(bp: BusquedaPaciente) {
    this.serviceBusquedaPaciente.formDataBuscado = Object.assign({}, bp);
    //this.serviceBusquedaPaciente.asignarIdPaciente(bp.idPaciente);
    const enviar: EnviarPaciente = new EnviarPaciente();
    enviar.idPaciente = bp.idPaciente;
    enviar.genero = bp.genero;
    this.messageEvent.emit(enviar);
    this.modalService.dismissAll();
  }

}
