import { Component, OnInit } from '@angular/core';
import {DashboardService} from "../service/dashboard.service";
import {Dashboard} from "../model/dashboard.model";
import {DashboardPie} from "../model/dashboard-pie.model";
import {DashboardLine} from "../model/dashboard-line.model";
import {HelperService} from "../service/helper.service";
import {UsuarioLogin} from "../model/usuario-login.model";
import {LoginService} from "../service/login.service";
import localeEs from "@angular/common/locales/es";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dash: Dashboard;
  dashPie: DashboardPie;
  dashLine: DashboardLine[];
  isAuthenticated = false;
  dashFecha: string = '';

  constructor(public dashService: DashboardService,
              private helper: HelperService,
              private loginService: LoginService) {
    this.dash = new Dashboard();
    this.dashPie = new DashboardPie();
  }

  ngOnInit(): void {
    this.mostrarFecha();
    this.helper.data.subscribe(res => {
      if(res)
      {
        this.isAuthenticated = res;
      }
    })

    if (!this.isNullUndefinesOrEmpty(sessionStorage.getItem('jwt'))) {
      this.loginService.getVerificar().subscribe(res => {
        if (res === null) {
          this.isAuthenticated = false;
        } else {
          this.isAuthenticated = true;
        }
      });
    } else {
      return null;
    }
    this.llenarInformacion();
  }

  private mostrarFecha() {
    const date = new Date();  // 2009-11-10
    const mes = date.toLocaleString('default', { month: 'long' });
    const dia = date.toLocaleString('es-PE', {  weekday: 'long' });
    const fecha = date.toLocaleString('default', {  day: 'numeric' });

    this.dashFecha = this.capitalizeFirstLetter(dia) +", "+ fecha + " de "+ mes;

  }


  public llenarInformacion() {
    this.dashService.getDashboard().subscribe(res=>
    {
      this.dash = res;
    })
    this.dashService.getPie().subscribe(res=> {
      this.dashPie = res;
    })

  }
  isNullUndefinesOrEmpty(value: string) {
    return value === null || value === undefined || value === '';
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }



}
