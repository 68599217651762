<div class="table-responsive box1">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
         class="table table-striped table-bordered row-border hover">
    <thead class="cabecera_tabla" >
    <tr>
      <th>Descripción</th>
      <th class="text-center "><i class="bi bi-trash"></i></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let pac of datos">
      <td class="text-center"(click)="populateForm(pac)">{{pac.descripcion}}</td>
      <td (click)="openModal(template,pac.idEspecialidad)" class="text-center">
        <button class="btn btn-sm btn-outline-danger"><i class="bi bi-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-template #template>
  <div class="modal-body text-center">
    <p>¿Quieres eliminar este registro?</p>
    <button type="button" class="btn btn-warning mr-2" (click)="confirm()" >Eliminar</button>
    <button type="button" class="btn btn-outline-warning" (click)="decline()" >Cancelar</button>
  </div>
</ng-template>

