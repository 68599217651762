export const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#DFFFD6',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  verde: {
    primary: '#2ECC71',
    secondary: '#ABEBC6',
  },

  programada: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  atendida: {
    primary: '#76c7c0',
    secondary: '#d4f1e5'
  },
  noAtendida: {
    primary: '#f7a1a1',
      secondary: '#fce4e4'
  }



};
