import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {Personal} from "../../model/personal.model";
import {PersonalService} from '../../service/personal.service';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-personal-lista',
  templateUrl: './personal-lista.component.html',
  styleUrls: ['./personal-lista.component.css']
})
export class PersonalListaComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  datos: Personal[];
  modalRef: BsModalRef;
  eliminar: number;

  constructor(public service: PersonalService,
              public toastr: ToastrService,
              private modalService: BsModalService) {
    this.service.listen().subscribe((m: any) => {
      this.service.getAllTodos().subscribe(rst => {
        this.datos = rst;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      language:
        {
          url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json'
        }
    };
    this.service.getAllTodos().subscribe(rst => {
      this.datos = rst;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    });

  }

  // tslint:disable-next-line:typedef
  populateForm(pac: Personal) {
    this.service.formData = Object.assign({}, pac);
  }

  // tslint:disable-next-line:typedef
  onDelete(idPersonal: number) {
      this.service.deletePersonal(idPersonal).subscribe(res => {
          this.service.getAllTodos().subscribe(rst => {
            this.datos = rst;
            // Destroy the table first
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          });
          this.toastr.warning('Eliminado correctamente', 'PERSONAL');
        }
      );

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  openModal(template: TemplateRef<any>, idEspecialidad: number) {
    this.eliminar = idEspecialidad;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    this.onDelete(this.eliminar);
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
