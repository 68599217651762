<app-busqueda-paciente (messageEvent)="receiveMessage($event)"></app-busqueda-paciente>
<div class="box1">
  <h3><i class="bi bi-cash-coin"></i> Pago</h3>
  <form #form="ngForm"  (ngSubmit)="form.valid && !submitted && onSubmit(form)" autocomplete="off">
    <div class="form-row boxModule">

      <div class="form-group col-md-3">
        <label>Código</label>
        <input name="idPago" #idPago="ngModel" [(ngModel)]="service.formData.idPago"
               class="form-control" readonly>
      </div>

      <div class="form-group col-md-6">
        <label>Concepto pago</label>
        <select #idConcepto="ngModel"
                name="idConcepto"
                [(ngModel)]="service.formData.idConcepto"
                class="form-control"
                [ngClass]="{ 'is-invalid': form.submitted && idConcepto.value==0 }" required
        >
          <option [ngValue]="objeto.idConcepto"
                  *ngFor="let objeto of listaConcepto">{{objeto.descripcion}}</option>
        </select>

      </div>
      <div class="form-group col-md-3">
        <label>Monto</label>
        <input
               name="monto"
               #monto="ngModel"
               [(ngModel)]="service.formData.monto"
               class="form-control"
               [ngClass]="{ 'is-invalid': form.submitted && monto.invalid||
                  (monto.invalid && (monto.dirty || monto.touched)),
                  'is-valid':monto.valid}"
               minlength="1"
               maxlength="9"
               [pattern]="patronNumeros"
               required
        >
        <div *ngIf="monto.invalid && (monto.dirty || monto.touched)"
             class="invalid-feedback">
          <div *ngIf="monto.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="monto.errors?.['pattern']">
            Escriba solo numeros.
          </div>
          <div *ngIf="monto.errors?.['maxlength']">
            Caracteres máx: 9.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <button type="button"  (click)="clickNuevo(form)" class="btn btn-info btn-block">Nuevo Pago
        </button>
      </div>
      <div class="form-group col-md-4">
        <button [disabled]="submitted " class="btn-warning btn btn-block">
          <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
          Grabar
        </button>
      </div>
      <div class="form-group col-md-4">
        <button type="button" (click)="form.reset()" class="btn btn-danger btn-block">Cancelar</button>
      </div>
    </div>
  </form>
</div>
