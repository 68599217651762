<div class="row paciente-registrado">
  <div class="col-md-8">
    <div class="form-check ">
      <input class="form-check-input mb-1 mt-3 "
             type="checkbox"
             name="esPacienteRegistrado"
             value=""
             checked="{{item}}"
             (change)="changePacienteRegistrado(modalBuscarPaciente)"
             [(ngModel)]="personaFiltrada.esPacienteRegistrado"
             id="esPacienteRegistrado">
      <label class="form-check-label mb-2 mt-1" for="esPacienteRegistrado">
        Buscar Paciente registrado
      </label>
    </div>
  </div>
</div>
<hr>


<ng-template #modalBuscarPaciente let-modal>

  <div class="modal-content">
    <div class="modal-header">
      <h3 class="col-md-10" id="modal-basic-title"><i class="bi bi-journal-text"></i> Buscar Paciente</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" class="btn-warning btn ">×</span>
      </button>
    </div>

    <div class="modal-body">
      <form #form="ngForm" (submit)="onSubmit(form)" autocomplete="off">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Elija tipo Búsq.:</label>
            <select #id="ngModel" name="id" [(ngModel)]="serviceBusquedaPaciente.formData.id"
                    class="form-control" required>
              <option [ngValue]="objeto.id"
                      *ngFor="let objeto of listaParametrico">{{ objeto.descripcion }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Descripción</label>
            <input name="descripcion" #descripcion="ngModel"
                   [(ngModel)]="serviceBusquedaPaciente.formData.descripcion"
                   class="form-control" required="required" pattern="[a-zA-Z0-9]+">
          </div>
          <div class="form-group col-md-12">
            <button type="submit" [disabled]="form.invalid" class="btn-warning btn btn-block bi bi-search"> Buscar
            </button>
          </div>
        </div>
      </form>

      <div class="table-responsive box1">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
               class="table table-striped table-bordered row-border hover">
          <thead class="cabecera_tabla">
          <tr>
            <th>Código</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Tipo Doc.</th>
            <th>Nro Doc.</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let pac of datos">
            <td (click)="populateForm(pac)">{{ pac.codigo }}</td>
            <td (click)="populateForm(pac)">{{ pac.nombres }}</td>
            <td (click)="populateForm(pac)">{{ pac.primerApellido }} {{ pac.segundoApellido }}</td>
            <td (click)="populateForm(pac)">{{ pac.tipoDocumento }}</td>
            <td (click)="populateForm(pac)">{{ pac.nroDocumento }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
  <!--  <div class="modal-footer">-->
  <!--    <button type="button" class="btn btn-danger" (click)="modal.dismiss('btn  click')">Cancelar</button>-->
  <!--  </div>-->
</ng-template>
