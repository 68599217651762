export class BusquedaPaciente {
  idPaciente: number;
  codigo: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  idTipoDocumento: number;
  tipoDocumento: string;
  nroDocumento:string;
  celular:string;
  genero: string;


}
