import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {HistoriaClinicaService} from '../../service/historia-clinica.service';
import {ToastrService} from "ngx-toastr";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {BusquedaPacienteService} from "../../service/busqueda-paciente.service";
import {BusquedaPaciente} from "../../model/busqueda-paciente.model";
import {DatePipe} from "@angular/common";
import {EnviarPaciente} from "../../model/personalizado/enviar-paciente.model";

@Component({
  selector: 'app-form-historias-clinicas',
  templateUrl: './form-historias-clinicas.component.html',
  styleUrls: ['./form-historias-clinicas.component.css']
})
export class FormHistoriasClinicasComponent implements OnInit {
  nroDocumento = '';

  idPacienteHeredado: number;
  idGeneroHeredado: string;
  datePipe = new DatePipe('es-PE');
  submitted: boolean = false;
  submittedPDF: boolean = false;
  //message:string;
  constructor(public service: HistoriaClinicaService,
              public serviceBusquedaPaciente: BusquedaPacienteService,
              private toastr: ToastrService,
              private bsLocalService: BsLocaleService) {
    this.bsLocalService.use('es');
  }

  ngOnInit(): void {
    this.resetForm();
    // this.serviceBusquedaPaciente.currentMessage.subscribe(
    //   message => (this.idPacienteHeredado= message)); //<= Always get current value!
  }

  // tslint:disable-next-line:typedef
  resetForm(form?: NgForm) {
    this.submitted = false;
    if (form != null) {
      form.resetForm();
    }

    this.service.formDataHistoria = {
      idHistoriaClinica: null,
      idPaciente: null,
      // pacienteFechaNacimiento: null,
      // pacienteEdadActual: 0,
      // pacienteGenero: '',
      // pacienteDireccion: '',
      // pacienteEstadoCivil: '',
      // pacientePersonaEmergencia: '',
      // pacienteCelularEmergencia: '',
      motivoConsulta: null,
      signosSintomasPrincipales: null,
      antecedentesFamiliares: null,
      bajoTratamientoMedico: null,
      bajoTratamientoMedicoDesc: null,
      tomandoMedicamento:null,
      tomandoMedicamentoDesc:null,
      alergico:null,
      alergicoDesc:null,
      diabetes:null,
      diabetesDesc:null,
      hepatitis:null,
      hepatitisDesc:null,
      hipertenso:null,
      hipertensoDesc:null,
      hemofilico:null,
      hemofilicoDesc:null,
      epilepsia:null,
      epilepsiaDesc:null,
      sananHeridas:null,
      tosYgripe:null,
      vicioHabito:null,
      vicioHabitoDesc:null,
      estaEmbarazada:null,
      estaEmbarazadaDesc:null,
      declararDetallesDesc:null,
      respirarPorBoca:null,
      dificultadesAbrirBoca:null,
      rechinaDientes:null,
      tomaLiquidosOscuros:null,
      sangranEncias:null,
      accesoriosHigieneOral:null,
      accesoriosHigieneOralDesc:null,
      fechaRegistro: null
    };


  }

  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    if(this.idPacienteHeredado==null || this.idPacienteHeredado == 0)
    {
      this.toastr.warning('Seleccione un paciente', 'HISTORIA CLÍNICA');
      this.submitted = false;
      return 0;
    }
    form.value.idPaciente = this.idPacienteHeredado;
    if (form.value.idHistoriaClinica == null || form.value.idHistoriaClinica === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }
  }


  // tslint:disable-next-line:typedef
  insertRecord(form: NgForm) {
    this.service.postHistoriaClinica(form.value).subscribe(res => {
      this.toastr.success('Insertado correctamente', 'HISTORIA CLÍNICA');
      this.resetForm();
      this.rellenarFormulario(form.value.idPaciente);
    });
  }

  // tslint:disable-next-line:typedef
  updateRecord(form: NgForm) {
    this.service.putHistoriaClinica(form.value).subscribe(res => {
      this.toastr.info('Actualizado correctamente', 'HISTORIA CLÍNICA');
      this.resetForm();
      this.rellenarFormulario(form.value.idPaciente);
    });
  }

  receiveMessage($event: EnviarPaciente) {

    this.idPacienteHeredado = $event.idPaciente;
    this.idGeneroHeredado = $event.genero;
    this.resetForm();
    this.rellenarFormulario(this.idPacienteHeredado);
  }

  rellenarFormulario(id: number) {
    this.service.getHistoriaClinicaPorPaciente(id).subscribe(res => {
      if(res!==null)
      {
        this.service.formDataHistoria = res;
      }

    });

  }

  clearBajoTratamientoMedicoDesc() {
    if(!this.service.formDataHistoria.bajoTratamientoMedico)
    {
      this.service.formDataHistoria.bajoTratamientoMedicoDesc = '';
    }
  }

  clearTomandoMedicamentoDesc() {
      if(!this.service.formDataHistoria.tomandoMedicamento)
      {
        this.service.formDataHistoria.tomandoMedicamentoDesc = '';
      }
    }

  clearAlergicoDesc() {
    if(!this.service.formDataHistoria.alergico)
    {
      this.service.formDataHistoria.alergicoDesc = '';
    }
  }
  clearDiabetesDesc() {
    if(!this.service.formDataHistoria.diabetes)
    {
      this.service.formDataHistoria.diabetesDesc = '';
    }
  }
  clearHepatitisDesc() {
    if(!this.service.formDataHistoria.hepatitis)
    {
      this.service.formDataHistoria.hepatitisDesc = '';
    }
  }
  cancelar() {
     this.resetForm();
    this.serviceBusquedaPaciente.filter()
  }
  clearHipertensoDesc() {
    if(!this.service.formDataHistoria.hipertenso)
    {
      this.service.formDataHistoria.hipertensoDesc = '';
    }
  }
  clearHemofilicoDesc() {
    if(!this.service.formDataHistoria.hemofilico)
    {
      this.service.formDataHistoria.hemofilicoDesc = '';
    }
  }
  clearEpilepsiaDesc() {
    if(!this.service.formDataHistoria.epilepsia)
    {
      this.service.formDataHistoria.epilepsiaDesc = '';
    }
  }


  clearVicioHabitoDesc() {
    if(!this.service.formDataHistoria.vicioHabito)
    {
      this.service.formDataHistoria.vicioHabitoDesc = '';
    }
  }
  clearEstaEmbarazadaDesc() {
    if(!this.service.formDataHistoria.estaEmbarazada)
    {
      this.service.formDataHistoria.estaEmbarazadaDesc = '';
    }
  }




  clearAccesoriosHigieneOralDesc() {
    if(!this.service.formDataHistoria.accesoriosHigieneOral)
    {
      this.service.formDataHistoria.accesoriosHigieneOralDesc = '';
    }
  }


  clickPDF() {
    this.submittedPDF = true;
    if(this.idPacienteHeredado==null || this.idPacienteHeredado == 0)
    {
      this.toastr.warning('Seleccione un paciente', 'HISTORIA CLÍNICA');
      this.submitted = false;
      this.submittedPDF = true;
      return 0;
    }
    this.service.tieneHistoriClinica(this.idPacienteHeredado).subscribe(
      res=>{
        if(!res)
        {
          this.toastr.warning('Paciente no tiene historia clinica', 'HISTORIA CLÍNICA');
          this.submitted = false;
          return 0;
        }
      }
    );

    this.service.getFichaPDF(this.idPacienteHeredado).subscribe((res) => {
      // saveAs(res, 'archivo.pdf');
      var fileURL = window.URL.createObjectURL(res);
      let tab = window.open();
      tab.location.href = fileURL;
      this.submittedPDF = false;
    }, error => {
    });
  }

  clickCancelar(form: NgForm) {
    form.reset();
    window.location.reload();
  }
}


