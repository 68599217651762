<form #formulario="ngForm" (submit)="onSubmit(formulario)" autocomplete="off">
  <div class="box1">
    <h3><i class="bi bi-person-fill"></i> Datos</h3>
    <input name="idPaciente" #idPaciente="ngModel" [(ngModel)]="service.formData.idPaciente"
           class="form-control" type="hidden">
    <div class="form-row">
      <div class="form-group col-md-4">
        <label>Código</label>
        <input name="codigo" #codigo="ngModel" [(ngModel)]="service.formData.codigo" class="form-control input_readonly" readonly>
        <!--        <div class="validation-error" *ngIf="codigo.invalid && codigo.touched">Este campo es necesario</div>-->
      </div>
      <div class="form-group col-md-4">
        <label>Tipo doc.<sup>*</sup></label>
        <select #idTipoDocumento="ngModel" name="idTipoDocumento" [(ngModel)]="service.formData.idTipoDocumento"
                required="required" class="form-control"
                [ngClass]="{ 'is-invalid': formulario.submitted && idTipoDocumento.value==0 }" required
        >
          <option [ngValue]="objeto.idTipoDocumento"
                  *ngFor="let objeto of listaTipoDocumento">{{objeto.descripcion}}</option>
        </select>

      </div>
      <div class="form-group col-md-4">
        <label>N° DNI<sup>*</sup></label>
        <input name="nroDocumento" #nroDocumento="ngModel"
               [(ngModel)]="service.formData.nroDocumento"

               [pattern]="patronNumerosLetras"
               [ngClass]="{ 'is-invalid': formulario.submitted && nroDocumento.invalid||
                  (nroDocumento.invalid && (nroDocumento.dirty || nroDocumento.touched)),
                  'is-valid':nroDocumento.valid}"
               minlength="8"
               maxlength="8"
               required
               class="form-control">
        <div *ngIf="nroDocumento.invalid && (nroDocumento.dirty || nroDocumento.touched)"
             class="invalid-feedback">
          <div *ngIf="nroDocumento.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="nroDocumento.errors?.['minlength']">
            Caracteres mín: 8.
          </div>
          <div *ngIf="nroDocumento.errors?.['maxlength']">
            Caracteres máx: 8.
          </div>
          <div *ngIf="nroDocumento.errors?.['pattern']">
            Escriba números y/o letras.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Nombres<sup>*</sup></label>
      <input name="nombres" #nombres="ngModel" [(ngModel)]="service.formData.nombres"
             class="form-control"
             required
             [ngClass]="{ 'is-invalid': formulario.submitted && nombres.invalid||
                  (nombres.invalid && (nombres.dirty || nombres.touched)),
                  'is-valid':nombres.valid}"
             maxlength="200"
             [pattern]="patronNombres"
             oninput="this.value = this.value.toUpperCase();"
      >
      <div *ngIf="nombres.invalid && (nombres.dirty || nombres.touched)"
           class="invalid-feedback">
        <div *ngIf="nombres.errors?.['required']">
          Campo requerido.
        </div>
        <div *ngIf="nombres.errors?.['pattern']">
          Escriba solo letras.
        </div>
        <div *ngIf="nombres.errors?.['maxlength']">
          Caracteres máx: 200.
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Primer apellido<sup>*</sup></label>
        <input name="primerApellido" #primerApellido="ngModel" [(ngModel)]="service.formData.primerApellido"
               class="form-control"
               required
               [ngClass]="{ 'is-invalid': formulario.submitted && primerApellido.invalid||
                  (primerApellido.invalid && (primerApellido.dirty || primerApellido.touched)),
                  'is-valid':primerApellido.valid}"
               maxlength="100"
               [pattern]="patronNombres"
               oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="primerApellido.invalid && (primerApellido.dirty || primerApellido.touched)"
             class="invalid-feedback">
          <div *ngIf="primerApellido.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="primerApellido.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="primerApellido.errors?.['maxlength']">
            Caracteres máx: 100.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Segundo apellido</label>
        <input name="segundoApellido" #segundoApellido="ngModel" [(ngModel)]="service.formData.segundoApellido"
               class="form-control"
               [ngClass]="{ 'is-invalid': formulario.submitted && segundoApellido.invalid||
                  (segundoApellido.invalid && (segundoApellido.dirty))}"
               maxlength="100"
               [pattern]="patronNombres"
               oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="segundoApellido.invalid && (segundoApellido.dirty || segundoApellido.touched)"
             class="invalid-feedback">

          <div *ngIf="segundoApellido.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="segundoApellido.errors?.['maxlength']">
            Caracteres máx: 100.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label>Género<sup>*</sup></label>
        <!--        <input name="genero" #genero="ngModel" [(ngModel)]="service.formData.genero" class="form-control" required>-->
        <select #genero="ngModel" name="genero" [(ngModel)]="service.formData.genero"
                required="required" class="form-control"
                [ngClass]="{ 'is-invalid': formulario.submitted && genero.value==0 }" required
        >
          <option [ngValue]="objeto.idGenero"
                  *ngFor="let objeto of listaGenero">{{objeto.descripcion}}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Estado civil<sup>*</sup></label>
        <!--        <input name="idEstadoCivil" #idEstadoCivil="ngModel" [(ngModel)]="service.formData.idEstadoCivil"-->
        <!--               class="form-control" required>-->
        <select #idEstadoCivil="ngModel" name="idEstadoCivil" [(ngModel)]="service.formData.idEstadoCivil"
                required="required" class="form-control"
                [ngClass]="{ 'is-invalid': formulario.submitted && idEstadoCivil.value==0 }" required
        >
          <option [ngValue]="objeto.idEstadoCivil"
                  *ngFor="let objeto of listaEstadoCivil">{{objeto.descripcion}}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Fec. nac.<sup>*</sup></label>
        <input name="fechaNacimiento"
               #fechaNacimiento="ngModel"
               [(ngModel)]="service.formData.fechaNacimiento"
               class="form-control"
               #dp="bsDatepicker"
               bsDatepicker [(bsValue)]="service.formData.fechaNacimiento"
               value="{{ service.formData.fechaNacimiento | date:'dd/MM/yyyy' }}"
               [ngClass]="{ 'is-invalid': formulario.submitted && fechaNacimiento.invalid||
                  (fechaNacimiento.invalid && (fechaNacimiento.dirty || fechaNacimiento.touched)),
                  'is-valid':fechaNacimiento.valid}"
               required

        >
        <div *ngIf="fechaNacimiento.invalid && (fechaNacimiento.dirty || fechaNacimiento.touched)"
             class="invalid-feedback">
          <div *ngIf="fechaNacimiento.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="fechaNacimiento.errors?.['pattern']">
            Ingrese una fecha: dd/mm/yyyy.
          </div>
        </div>

      </div>
    </div>

    <div class="form-group">
      <label>Dirección</label>
      <input name="direccion" #direccion="ngModel" [(ngModel)]="service.formData.direccion"
             [ngClass]="{}"
             maxlength="100"
             class="form-control"
             oninput="this.value = this.value.toUpperCase();"
      >
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Celular<sup>*</sup></label>
        <input name="celular" #celular="ngModel" [(ngModel)]="service.formData.celular"
               [ngClass]="{ 'is-invalid': formulario.submitted && celular.invalid||
                  (celular.invalid && (celular.dirty || celular.touched)),
                  'is-valid':celular.valid}"
               size="9"
               minlength="9"
               maxlength="9"
               [pattern]="patronNumeros"
               class="form-control"
               required>
        <div *ngIf="celular.invalid && (celular.dirty || celular.touched)"
             class="invalid-feedback">
          <div *ngIf="celular.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="celular.errors?.['pattern']">
            Escriba solo numeros.
          </div>
          <div *ngIf="celular.errors?.['minlength']">
            Caracteres mín: 9.
          </div>
          <div *ngIf="celular.errors?.['maxlength']">
            Caracteres máx: 9.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>E-mail<sup>*</sup></label>
        <input
          name="email"
          #email="ngModel"
          [(ngModel)]="service.formData.email"
          class="form-control"
          [ngClass]="{
      'is-invalid':
        (formulario.submitted && email.invalid) ||
        (email.invalid && (email.dirty || email.touched)),
      'is-valid': email.valid && email.value
    }"
          maxlength="50"
          [pattern]="patronEmail"
          required
        />
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
          <div *ngIf="email.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="email.errors?.['pattern']">
            Ejemplo: correo@gmail.com
          </div>
          <div *ngIf="email.errors?.['maxlength']">
            Caracteres máx: 50.
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <sup>(*) Estos campos son obligatorios</sup>
      </div>
    </div>
  </div>

  <!--<div class="box1 mb-3 mt-3">
    <h3><i class="bi bi-telephone-fill"></i> Contacto de emergencia</h3>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Nombre completo<sup>*</sup></label>
        <input name="personaEmergencia" #nombresContacto="ngModel" [(ngModel)]="service.formData.nombresContacto"
               class="form-control"
               required
               [ngClass]="{ 'is-invalid': formulario.submitted && nombresContacto.invalid||
                  (nombresContacto.invalid && (nombresContacto.dirty || nombresContacto.touched)),
                  'is-valid':nombresContacto.valid}"
               maxlength="200"
               [pattern]="patronNombres"
        >
        <div *ngIf="nombresContacto.invalid && (nombresContacto.dirty || nombresContacto.touched)"
             class="invalid-feedback">
          <div *ngIf="nombresContacto.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="nombresContacto.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="nombresContacto.errors?.['maxlength']">
            Caracteres máx: 200.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Celular<sup>*</sup></label>
        <input name="nroEmergencia" #nroEmergencia="ngModel" [(ngModel)]="service.formData.documentoContacto"
               [ngClass]="{ 'is-invalid': formulario.submitted && nroEmergencia.invalid||
                  (nroEmergencia.invalid && (nroEmergencia.dirty || nroEmergencia.touched)),
                  'is-valid':nroEmergencia.valid}"
               size="9"
               minlength="9"
               maxlength="9"
               [pattern]="patronNumeros"
               class="form-control"
               required>
        <div *ngIf="nroEmergencia.invalid && (nroEmergencia.dirty || nroEmergencia.touched)"
             class="invalid-feedback">
          <div *ngIf="nroEmergencia.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="nroEmergencia.errors?.['pattern']">
            Escriba solo numeros.
          </div>
          <div *ngIf="nroEmergencia.errors?.['minlength']">
            Caracteres mín: 9.
          </div>
          <div *ngIf="nroEmergencia.errors?.['maxlength']">
            Caracteres máx: 9.
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <sup>(*) Estos campos son obligatorios.</sup>
      </div>
    </div>
  </div>-->
  <div class="box1 mb-3 mt-3">
    <h3><i class="bi bi-hand-index-thumb"></i> Opciones</h3>

    <div class="form-row">
      <div class="form-group col-md-4">
        <button type="button" (click)="formulario.reset()" class="btn btn-info btn-block">Nuevo Registro
        </button>
      </div>
      <div class="form-group col-md-4">
<!--        <button type="submit" [disabled]="formulario.invalid" class="btn-warning btn btn-block">Grabar</button>-->
        <button [disabled]="submitted " class="btn-warning btn btn-block">
          <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
          Grabar
        </button>
      </div>
      <div class="form-group col-md-4">
        <button type="button" (click)="formulario.reset()" class="btn btn-danger btn-block">Cancelar</button>
      </div>
    </div>
  </div>

</form>

