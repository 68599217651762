import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Paciente } from '../model/paciente.model';
import { Respuesta } from '../model/respuesta.model';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
  private rootURL = environment.baseUrl;
  formData: Paciente;
  list: Paciente[];
  private listeners = new Subject<any>();

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': sessionStorage.getItem('jwt') || ''
    });
  }

  postPaciente(formData: Paciente): Observable<Respuesta> {
    return this.http.post<Respuesta>(`${this.rootURL}/Paciente/Insertar`, formData, { headers: this.getHeaders() });
  }

  putPaciente(formData: Paciente): Observable<Respuesta> {
    return this.http.put<Respuesta>(`${this.rootURL}/Paciente/Actualizar`, formData, { headers: this.getHeaders() });
  }

  getAllTodos(): Observable<Paciente[]> {
    return this.http.get<Paciente[]>(`${this.rootURL}/Paciente/Listar`, { headers: this.getHeaders() })
      .pipe(map(data => data.map(item => Object.assign(new Paciente(), item))));
  }

  deletePaciente(id: number): Observable<any> {
    return this.http.delete(`${this.rootURL}/Paciente/Eliminar?idPaciente=${id}`, { headers: this.getHeaders() });
  }

  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(): void {
    this.listeners.next();
  }

  busquedaPorDocumento(nroDocumento: string): Observable<Paciente> {
    return this.http.get<Paciente>(`${this.rootURL}/Paciente/BusquedaPorDocumento?nroDocumento=${nroDocumento}`, { headers: this.getHeaders() });
  }
}
