<div class="container-fluid">
  <h1 class="display-4 text-center">Registro de Paciente</h1>
  <hr>
  <div class="row">
    <div class="col-md-5">
      <app-paciente></app-paciente>
    </div>
    <div class="col-md-7">
      <app-paciente-lista>
      </app-paciente-lista>
    </div>
  </div>
</div>





