import {Component, OnInit} from '@angular/core';
import {LoginService} from '../service/login.service';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UsuarioLogin} from '../model/usuario-login.model';
import {HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {HelperService} from "../service/helper.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginresponse: UsuarioLogin;
  submitted: boolean = false;
  constructor(public  service: LoginService,
              private toastr: ToastrService,
              private router: Router,
              private helper: HelperService) {
  }

  ngOnInit(): void {
    this.resetForm();
  }

  // tslint:disable-next-line:typedef
  resetForm(form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      usuario: '',
      contrasenia: '',
      token: ''
    };
  }


  // tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    this.submitted = true;
    this.service.postLogin(form.value).subscribe(res => {
      this.loginresponse = (res as UsuarioLogin);
      if (this.loginresponse.token === '') {
        this.toastr.error('Usuario o Contraseña incorrecta', 'Autenticación');
        this.submitted = false;
      } else {
        this.toastr.success('Bienvenido!', 'Autenticación');
        sessionStorage.setItem('jwt', this.loginresponse.token);
        this.helper.actualizarAutenticidad(true);
        this.router.navigate(['/']);
        this.submitted = false;
      }

    });
  }

  // tslint:disable-next-line:typedef
  isNullUndefinesOrEmpty(value: string) {
    return value === null || value === undefined || value === '';
  }
}
