export class Personal {
  idPersonal: number;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  celular: number;
  email: string;
  idEspecialidad: number;
  nombreEspecialidad: string;
  fechaRegistro: Date;
  eliminado: number;
}
