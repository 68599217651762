export class TipoDocumento {

  constructor(idTipoDocumento: number, descripcion: string, eliminado: number) {
    this.idTipoDocumento = idTipoDocumento;
    this.descripcion = descripcion;
    this.eliminado = eliminado;
  }

  idTipoDocumento: number;
  descripcion: string;
  eliminado: number;
}
