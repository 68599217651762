import {Component, OnInit} from '@angular/core';
import {DashboardService} from "../../service/dashboard.service";
import {DashboardPie} from "../../model/dashboard-pie.model";
import {HelperService} from "../../service/helper.service";

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
  type = 'doughnut';
  data = {};
  options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {position: 'right'},
    title: {text: 'Pacientes por Género', display: true,fontSize: 15}
  };
  dashPie: DashboardPie;


  type2 = 'doughnut';
  data2 = {};
  options2 = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {position: 'right'},
    title: {text: 'Pacientes por edad', display: true,fontSize: 15}
  };
  dashPie2: DashboardPie;
  constructor(public serviceDash: DashboardService, private helper: HelperService,) {
  }

  ngOnInit(): void {
    this.helper.data.subscribe(res => {
      if(res)
      {
        this.cargar();
      }
    })
  }

  private cargar() {
    this.serviceDash.getPie().subscribe(res => {
      this.dashPie = res;
      this.data = {
        labels: ["Femenino", "Masculino"],
        datasets: [
          {
            label: "Pie mujeres/varones",

            data: [this.dashPie.totalMujeres, this.dashPie.totalVarones],
            backgroundColor: [
              "#F56FAD",
              "#33AED3"
            ]
          },
        ]
      };
      this.data2 = {
        labels: ["Menores", "Adulto", "Adulto Mayor"],
        datasets: [
          {
            label: "Pie",
            data: [this.dashPie.totalMenores, this.dashPie.totalMayores, this.dashPie.totalAdultoMayor],
            backgroundColor: [
              "#FFBF46",
              "#F56FAD",
              "#975AFF"
            ]
          },
        ]
      };
    });
  }

}
