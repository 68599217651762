export class Paciente {
  idPaciente: number;
  codigo: string;
  idTipoDocumento: number;
  nroDocumento: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: Date;
  celular: string;
  email: string;
  genero: string;
  idEstadoCivil: number;
  direccion: string;
  celularContacto: string;
  nombresContacto: string;
  apellidosContacto: string;
  documentoContacto: string;
  idParentesco: number;
  fechaRegistro: Date;
  eliminado: number;
}
