import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {FormBuilder, NgForm, Validators} from '@angular/forms';
import {PersonalService} from '../../service/personal.service';
import {Especialidad} from '../../model/especialidad.model';
import {EspecialidadService} from '../../service/especialidad.service';


@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {
  submitted: boolean = false;
  patronNombres = "^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$";
  patronNumeros = "^[0-9]*";
  patronEmail = "^[a-z]+[a-z0-9._-]+@[a-z]+\\.[a-z.]{2,5}$";
  listaEspecialidad: Especialidad[];

  constructor(public service: PersonalService, public serviceEspecialidad: EspecialidadService,
              private toastr: ToastrService, private bsLocaleService: BsLocaleService
  ) {
    this
      .bsLocaleService
      .use(
        'es'
      );
  }

  ngOnInit():
    void {
    this.resetForm();
    this.getEspecilidades();
  }


// tslint:disable-next-line:typedef
  resetForm(form ?: NgForm) {
    this.submitted = false;
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      idPersonal: null,
      nombres: null,
      primerApellido: null,
      segundoApellido: null,
      celular: null,
      email: null,
      idEspecialidad: 0,
      nombreEspecialidad: null,
      fechaRegistro: new Date(),
      eliminado: 0,
    };
  }

// tslint:disable-next-line:typedef
  onSubmit(form: NgForm) {
    if (this.service.formData.idEspecialidad == 0) {
      return false;
    }
    this.submitted = true;
    if (form.value.idPersonal == null || form.value.idPersonal === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }

  }

// tslint:disable-next-line:typedef
  getEspecilidades() {
    this.serviceEspecialidad.getAllTodos().subscribe(res => this.listaEspecialidad = res);
  }

// tslint:disable-next-line:typedef
  insertRecord(form: NgForm) {
    this.service.postPersonal(form.value).subscribe(res => {
      this.toastr.success('Insertado correctamente', 'PERSONAL');
      this.resetForm(form);
      this.service.filter();
    });
  }

// tslint:disable-next-line:typedef
  updateRecord(form
                 :
                 NgForm
  ) {
    this.service.putPersonal(form.value).subscribe(res => {
      this.toastr.info('Actualizado correctamente', 'PERSONAL');
      this.resetForm(form);
      this.service.filter();
    });
  }

}

