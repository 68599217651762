<div class="table-responsive box1">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
         class="table table-striped table-bordered row-border hover">
    <thead class="cabecera_tabla">
    <tr>
      <th>Código</th>
      <th>Monto</th>
      <th>Fecha</th>
      <th>Concepto</th>
      <th class="text-xl-center"><i class="bi bi-trash "></i></th>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let item of datos">
      <td class="text-center"(click)="populateForm(item)">{{item.idPago}}</td>
      <td class="text-right" (click)="populateForm(item)">{{item.monto| currency:'code': 'S/.'}}</td>
      <td  class="text-center form-group col-md-3" (click)="populateForm(item)">{{item.fechaPago | date:'dd/MM/yyyy HH:mm:ss'}}</td>
      <td CLASS="text-center" (click)="populateForm(item)">{{item.descripcionConcepto}}</td>
      <td (click)="openModal(template,item.idPago)" class="text-center">
        <button class="btn btn-sm btn-outline-danger"><i class="bi bi-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
  <div>
    <p>Total: S/{{total}}</p>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>¿Quieres eliminar este registro?</p>
    <button type="button" class="btn btn-warning mr-2" (click)="confirm()" >Eliminar</button>
    <button type="button" class="btn btn-outline-warning" (click)="decline()" >Cancelar</button>
  </div>
</ng-template>

