import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-historias-clinicas',
  templateUrl: './historias-clinicas.component.html',
  styleUrls: ['./historias-clinicas.component.css']
})
export class HistoriasClinicasComponent implements OnInit {


  constructor() {


  }

  ngOnInit(): void {


  }


}
