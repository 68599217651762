export class Citas {
  idCita: number;
  idPaciente: number;
  asuntoCita: number;
  idPersonal: number;
  idEspecialidad: number;
  fechaCita: Date;
  tiempoInicio: number;
  tiempoFin: number;
  esPacienteRegistrado: boolean;
  nombreCompleto: string;
  documento: string;
  celular:string;
  descripcion: string;
  fechaReprogramada:Date;
  citaReprogramada: boolean;
  citaAtendida: boolean;

}
