<div class="container-fluid">
  <h1 class="display-4 text-center">Reporte de Indicadores</h1>
  <hr>

  <div id="ReporteCompleto">
    <div class="row">
      <!-- Reporte de Citas Atendidas -->
      <div class="col-md-6" id="ReporteCitasAtendidas">
        <h4 class="text-center">Nivel de Citas Atendidas</h4>
        <table class="table table-bordered table-sm shadow-sm rounded">
          <thead class="bg-primary text-white">
          <tr class="bg-info">
            <th colspan="3" class="text-center text-white">Ficha de Registro</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="font-weight-bold text-nowrap">Empresa</td>
            <td colspan="2" class="text-left">Consultorio Odontológico TIVONIDENT</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Proceso</td>
            <td colspan="2" class="text-left">Control de Citas</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Fórmula</td>
            <td colspan="2" class="text-left">PCA: (PAC/PCP) * 100</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Indicador</td>
            <td colspan="2" class="text-left">Nivel de Citas Atendidas</td>
          </tr>
          <tr>
            <td colspan="3" class="bg-light text-sm">

              <p class="mb-1"><strong>PCA:</strong> porcentaje de pacientes que asisten a la cita</p>
              <p class="mb-1"><strong>PAC:</strong> pacientes que asisten a la cita</p>
              <p class="mb-1"><strong>PCP:</strong> pacientes con citas programadas</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="table-responsive box1">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                 class="table table-striped table-bordered row-border hover">
            <thead class="cabecera_tabla">
            <tr>
              <th class="text-center">Ítem</th>
              <th class="text-center">Fecha</th>
              <th class="text-center">PAC</th>
              <th class="text-center">PCP</th>
              <th class="text-center">PCA</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pac of datos; index as i">
              <td class="text-center" (click)="populateForm(pac)">{{ i + 1 }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.fecha | date:'dd/MM/yyyy' }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.totalAtendidas }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.totalCitas }}</td>
              <td class="text-center" (click)="populateForm(pac)">
                {{ ((pac.totalAtendidas / pac.totalCitas) * 100).toFixed(1) }} %
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Reporte de Citas Reprogramadas -->
      <div class="col-md-6" id="ReporteCitasReprogramadas">
        <h4 class="text-center">Nivel de Citas Reprogramadas</h4>
        <table class="table table-bordered table-sm shadow-sm rounded">
          <thead class="bg-primary text-white">
          <tr class="bg-info">
            <th colspan="3" class="text-center text-white">Ficha de Registro</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="font-weight-bold text-nowrap">Empresa</td>
            <td colspan="2" class="text-left">Consultorio Odontológico TIVONIDENT</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Proceso</td>
            <td colspan="2" class="text-left">Control de Citas</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Fórmula</td>
            <td colspan="2" class="text-left">PCR: (NCR/NTCP) * 100</td>
          </tr>
          <tr>
            <td class="font-weight-bold text-nowrap">Indicador</td>
            <td colspan="2" class="text-left">Nivel de Citas Reprogramadas</td>
          </tr>
          <tr>
            <td colspan="3" class="bg-light text-sm">
              <p class="mb-1"><strong>PCR:</strong> porcentaje de pacientes reprogramadas</p>
              <p class="mb-1"><strong>NCR:</strong> Número de citas reprogramadas</p>
              <p class="mb-1"><strong>NTCP:</strong> Número total de citas programadas</p>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-responsive box1">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                 class="table table-striped table-bordered row-border hover">
            <thead class="cabecera_tabla">
            <tr>
              <th class="text-center">Ítem</th>
              <th class="text-center">Fecha</th>
              <th class="text-center">NCR</th>
              <th class="text-center">NTCP</th>
              <th class="text-center">PCR</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pac of datos; index as i">
              <td class="text-center" (click)="populateForm(pac)">{{ i + 1 }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.fecha | date:'dd/MM/yyyy' }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.totalReprogramadas }}</td>
              <td class="text-center" (click)="populateForm(pac)">{{ pac.totalCitas }}</td>
              <td class="text-center" (click)="populateForm(pac)">
                {{ ((pac.totalReprogramadas / pac.totalCitas) * 100).toFixed(1) }} %
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6 text-center">
      <button class="btn btn-primary font-weight-bold col-md-8" (click)="exportToPDF('ReporteCitasAtendidas')">Exportar Citas Atendidas a PDF</button>
    </div>
    <div class="col-md-6 text-center">
      <button class="btn btn-primary font-weight-bold col-md-8" (click)="exportToPDF('ReporteCitasReprogramadas')">Exportar Citas Reprogramadas a PDF</button>
    </div>
  </div>
</div>
