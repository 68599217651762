<div class="table-responsive box1">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
         class="table table-striped table-bordered row-border hover">
    <thead class="cabecera_tabla">
    <tr>
      <th>Código</th>
      <th>Nombres</th>
      <th>Apellidos</th>
      <th>N°DNI</th>
      <!--      <th>Celular</th>-->
      <th class="text-center"><i class="bi bi-trash"></i></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let pac of datos">
      <td class="text-center" (click)="populateForm(pac)">{{ pac.codigo }}</td>
      <td class="texto2" (click)="populateForm(pac)">{{ pac.nombres }}</td>
      <td class="texto2" (click)="populateForm(pac)">{{ pac.primerApellido }} {{ pac.segundoApellido }}</td>
      <td class="text-center" (click)="populateForm(pac)">{{ pac.nroDocumento }}</td>
      <!--      <td class="text-center" (click)="populateForm(pac)">{{ pac.celular }}</td>-->

      <td (click)="openModal(template,pac.idPaciente)" class="text-center">
        <button class="btn btn-sm btn-outline-danger"><i class="bi bi-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-template #template>
  <div class="modal-body modal-sm text-center ">
    <p>¿Quieres eliminar este registro?</p>
    <button type="button" class="btn btn-outline-warning mr-2 " (click)="confirm()">SI</button>
    <button type="button" class="btn btn-warning mr2" (click)="decline()">NO</button>
  </div>
</ng-template>
