import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PacienteService } from '../../service/paciente.service';
import { Paciente } from '../../model/paciente.model';

@Component({
  selector: 'app-paciente-lista',
  templateUrl: './paciente-lista.component.html',
  styleUrls: ['./paciente-lista.component.css']
})
export class PacienteListaComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  datos: Paciente[];
  modalRef: BsModalRef;
  eliminarId: number;

  constructor(
    private pacienteService: PacienteService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: { url: 'https://cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json' },
      destroy: true
    };
    this.loadPacientes();

    // Suscribirse al evento de filtro para actualizar la tabla cuando se grabe un nuevo paciente
    this.pacienteService.listen().subscribe(() => {
      this.loadPacientes();
    });
  }

  loadPacientes(): void {
    this.pacienteService.getAllTodos().subscribe(data => {
      this.datos = data;
      this.rerender();
    });
  }

  rerender(): void {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next(); // Asegura que se dispare cuando dtInstance aún no está disponible
    }
  }

  populateForm(paciente: Paciente): void {
    paciente.fechaNacimiento = new Date(paciente.fechaNacimiento);
    this.pacienteService.formData = Object.assign({}, paciente);
  }

  onDelete(idPaciente: number): void {
    this.pacienteService.deletePaciente(idPaciente).subscribe(() => {
      this.toastr.warning('Eliminado correctamente', 'PACIENTE');
      this.loadPacientes();
    });
  }

  openModal(template: TemplateRef<any>, idEspecialidad: number): void {
    this.eliminarId = idEspecialidad;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.onDelete(this.eliminarId);
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
