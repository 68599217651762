import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HistoriaClinica} from "../model/historia-clinica.model";
import {Dashboard} from "../model/dashboard.model";
import {DashboardPie} from "../model/dashboard-pie.model";
import {DashboardLine} from "../model/dashboard-line.model";
import {Paciente} from "../model/paciente.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {

  }
  // tslint:disable-next-line:typedef
  getDashboard(): Observable<Dashboard> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get<Dashboard>(this.rootURL + '/Dashboard/Cuadros', {headers: reqHeader});
  }
  // tslint:disable-next-line:typedef
  getPie(): Observable<DashboardPie> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get<DashboardPie>(this.rootURL + '/Dashboard/Pie', {headers: reqHeader});
  }
  // tslint:disable-next-line:typedef
  getLine(): Observable<DashboardLine[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Dashboard/Line', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new DashboardLine();
        Object.assign(model, item);
        return model;
      }))
    );
  }
}
