import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Paciente} from '../model/paciente.model';
import {Especialidad} from "../model/especialidad.model";
import {HistoriaClinica} from "../model/historia-clinica.model";
import {HistoriaClinicaForm} from "../model/historia-clinica-form.model";

@Injectable({
  providedIn: 'root'
})
export class HistoriaClinicaService {

  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formDataHistoria: HistoriaClinica;

  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();


  // tslint:disable-next-line:typedef
  postHistoriaClinica(formDataHistoria: HistoriaClinica) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.post(this.rootURL + '/HistoriaClinica/Insertar', formDataHistoria, {headers: reqHeader});
  }


  // tslint:disable-next-line:typedef
  putHistoriaClinica(formDataHistoria: HistoriaClinica) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.put(this.rootURL + '/HistoriaClinica/Actualizar', formDataHistoria, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  getHistoriaClinicaPorPaciente(id: number): Observable<HistoriaClinica> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get<HistoriaClinica>(this.rootURL + '/HistoriaClinica/HistoriaClinicaPorPaciente?idPaciente=' + id, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  deleteHistoriaClinica(id: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.delete(this.rootURL + '/HistoriaClinica/Eliminar?idHistoriaClinica=' + id, {headers: reqHeader});
  }



  public getFichaPDF(id: number) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get(this.rootURL + '/HistoriaClinica/VerFichaPDF?idPaciente=' + id, {headers: reqHeader, responseType: "blob"}).pipe (
      tap (
        // Log the result or error
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
  }
  public tieneHistoriClinica(id: number):Observable<boolean>{
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.get<boolean>(this.rootURL + '/HistoriaClinica/TieneHistoriaClinica?idPaciente=' + id, {headers: reqHeader});

  }




  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }
}
