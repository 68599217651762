import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Pago} from "../../model/pago.model";
import {PagoService} from "../../service/pago.service";
import {ToastrService} from "ngx-toastr";
import {PagoDescripcion} from "../../model/pago-descripcion.model";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pago-lista',
  templateUrl: './pago-lista.component.html',
  styleUrls: ['./pago-lista.component.css']
})
export class PagoListaComponent implements OnInit {
  total: number = 0;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  datos: PagoDescripcion[];
  modalRef: BsModalRef;
  eliminar: number;

  constructor(public service: PagoService,
              public toastr: ToastrService,
              private modalService: BsModalService) {

    this.service.listen().subscribe((m: any) => {
      this.service.obtenerPagosPorPaciente().subscribe(rst => {
        this.datos = rst;
        this.total = 0;
        this.datos.forEach(
          item=>{
            this.total+=item.monto;
          }
        )

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language:
        {
          url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json'
        },
      order:[[2, 'desc']]
    };
    this.service.obtenerPagosPorPaciente().subscribe(rst => {
      this.datos = rst;
      this.total = 0;
      this.datos.forEach(
        item=>{
          this.total+=item.monto;
        }
      )
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    });
  }

  // tslint:disable-next-line:typedef
  price: any;

  populateForm(pagoDescripcion: PagoDescripcion) {
    const pagoElemento: Pago = new Pago();

    pagoElemento.idPago = pagoDescripcion.idPago;
    pagoElemento.fechaPago = pagoDescripcion.fechaPago;
    pagoElemento.idPaciente = pagoDescripcion.idPaciente;
    pagoElemento.monto = pagoDescripcion.monto;
    pagoElemento.idConcepto = pagoDescripcion.idConcepto;
    this.service.formData = Object.assign({}, pagoElemento);
  }

  onDelete(idPago: number) {

      this.service.deletePago(idPago).subscribe(res => {
          this.service.obtenerPagosPorPaciente().subscribe(rst => {
            this.datos = rst;
            this.total = 0;
            this.datos.forEach(
              item=>{
                this.total+=item.monto;
              }
            )
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          });
          this.toastr.warning('Eliminado correctamente', 'Pago');
        }
      );

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  openModal(template: TemplateRef<any>, idEspecialidad: number) {
    this.eliminar = idEspecialidad;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    this.onDelete(this.eliminar);
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
