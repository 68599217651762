import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Especialidad} from '../model/especialidad.model';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadService {

  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formData: Especialidad;
  list: Especialidad[];

  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();


  // tslint:disable-next-line:typedef
  postEspecialidad(formData: Especialidad) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.post(this.rootURL + '/Especialidad/Insertar', formData, {headers: reqHeader});
  }


  // tslint:disable-next-line:typedef
  putEspecialidad(formData: Especialidad) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.put(this.rootURL + '/Especialidad/Actualizar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  getAllTodos(): Observable<Especialidad[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Especialidad/Listar', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Especialidad();
        Object.assign(model, item);
        return model;
      }))
    );
  }
  // tslint:disable-next-line:typedef
  deleteEspecialidad(id: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.delete(this.rootURL + '/Especialidad/Eliminar?idEspecialidad=' + id, {headers: reqHeader});
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }
}
