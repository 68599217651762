import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import { Parametrico } from '../model/parametrico.model';
import { BusquedaPaciente } from '../model/busqueda-paciente.model';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class BusquedaPacienteService {
  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) { }


  //enviar a otro componente
  // public editDataDetails: any = [];
  // public subject = new Subject<any>();
  // private messageSource = new  BehaviorSubject(this.editDataDetails);
  // currentMessage = this.messageSource.asObservable();
  // asignarIdPaciente(message: number) {
  //   this.messageSource.next(message)
  // }
// fin enviar a otro componente






  formData: Parametrico;
  formDataBuscado: BusquedaPaciente;

  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();

  // tslint:disable-next-line:typedef
  getListarTipoBusquedaPaciente(): Observable<Parametrico[]> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Parametrico/ListarTipoBusquedaPaciente', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Parametrico();
        Object.assign(model, item);
        return model;
      }))
    );
  }

  // tslint:disable-next-line:typedef
  getListarBusquedaPaciente(formData: Parametrico): Observable<BusquedaPaciente[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.post(this.rootURL + '/Parametrico/ListarBusquedaPaciente', formData, {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new BusquedaPaciente();
        Object.assign(model, item);
        return model;
      }))
    );
  }


  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }

}
