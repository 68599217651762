<div class="box1">
  <h3><i class="bi bi-person-lines-fill"></i> Datos Personal</h3>
  <form #form="ngForm"  (ngSubmit)="form.valid && !submitted && onSubmit(form)" autocomplete="off">

    <div class="form-row boxModulo">

      <div class="form-group col-md-3" hidden>
        <label>Código</label>
        <input name="idPersonal" #idPersonal="ngModel" [(ngModel)]="service.formData.idPersonal"
               class="form-control input_readonly" readonly>
      </div>

      <div class="form-group col-md-12">
        <label>Especialidad<sup>*</sup></label>
        <select #idEspecialidad="ngModel" name="idEspecialidad" [(ngModel)]="service.formData.idEspecialidad"
                class="form-control"
                [ngClass]="{ 'is-invalid': form.submitted && idEspecialidad.value==0 }" required
        >
          <option [ngValue]="objeto.idEspecialidad"
                  *ngFor="let objeto of listaEspecialidad">{{objeto.descripcion}}</option>
        </select>
      </div>
      <div class="form-group col-md-12">
        <label>Nombres<sup>*</sup></label>
        <input name="nombres"
               #nombres="ngModel"
               [(ngModel)]="service.formData.nombres"
               required
               [ngClass]="{ 'is-invalid': form.submitted && nombres.invalid||
                  (nombres.invalid && (nombres.dirty || nombres.touched)),
                  'is-valid':nombres.valid}"
               maxlength="200"
               [pattern]="patronNombres"

               class="form-control"
               oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="nombres.invalid && (nombres.dirty || nombres.touched)"
             class="invalid-feedback">
          <div *ngIf="nombres.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="nombres.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="nombres.errors?.['maxlength']">
            Caracteres máx: 200.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Primer Apellido<sup>*</sup></label>
        <input name="primerApellido"
               #primerApellido="ngModel"
               [(ngModel)]="service.formData.primerApellido"
               class="form-control"
               required
               [ngClass]="{ 'is-invalid': form.submitted && primerApellido.invalid||
                  (primerApellido.invalid && (primerApellido.dirty || primerApellido.touched)),
                  'is-valid':primerApellido.valid}"
               maxlength="100"
               [pattern]="patronNombres"
               oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="primerApellido.invalid && (primerApellido.dirty || primerApellido.touched)"
             class="invalid-feedback">
          <div *ngIf="primerApellido.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="primerApellido.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="primerApellido.errors?.['maxlength']">
            Caracteres máx: 100.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Segundo Apellido</label>
        <input name="segundoApellido"
               #segundoApellido="ngModel"
               [(ngModel)]="service.formData.segundoApellido"
               class="form-control"
               [ngClass]="{ 'is-invalid': form.submitted && segundoApellido.invalid||
                  (segundoApellido.invalid && (segundoApellido.dirty || segundoApellido.touched))}"
               maxlength="100"
               [pattern]="patronNombres"
                oninput="this.value = this.value.toUpperCase();"
        >
        <div *ngIf="segundoApellido.invalid && (segundoApellido.dirty || segundoApellido.touched)"
             class="invalid-feedback">

          <div *ngIf="segundoApellido.errors?.['pattern']">
            Escriba solo letras.
          </div>
          <div *ngIf="segundoApellido.errors?.['maxlength']">
            Caracteres máx: 100.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Celular<sup>*</sup></label>
        <input name="celular"
               #celular="ngModel"
               [(ngModel)]="service.formData.celular"
               class="form-control"

               [ngClass]="{ 'is-invalid': form.submitted && celular.invalid||
                  (celular.invalid && (celular.dirty || celular.touched)),
                  'is-valid':celular.valid}"
               size="9"
               minlength="9"
               maxlength="9"
               [pattern]="patronNumeros"
               required

        >
        <div *ngIf="celular.invalid && (celular.dirty || celular.touched)"
             class="invalid-feedback">
          <div *ngIf="celular.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="celular.errors?.['pattern']">
            Escriba solo numeros.
          </div>
          <div *ngIf="celular.errors?.['minlength']">
            Caracteres mín: 9.
          </div>
          <div *ngIf="celular.errors?.['maxlength']">
            Caracteres máx: 9.
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label>Correo<sup>*</sup></label>
        <input name="email"
               #email="ngModel"
               [(ngModel)]="service.formData.email"
               type="email"
               [ngClass]="{ 'is-invalid': form.submitted && email.invalid||
                  (email.invalid && (email.dirty || email.touched)),
                  'is-valid':email.valid}"
               maxlength="50"
               [pattern]="patronEmail"
               class="form-control"
               required
        >
        <div *ngIf="email.invalid && (email.dirty || email.touched)"
             class="invalid-feedback">
          <div *ngIf="email.errors?.['required']">
            Campo requerido.
          </div>
          <div *ngIf="email.errors?.['pattern']">
            Ejemplo: correo@gmail.com
          </div>
          <div *ngIf="email.errors?.['maxlength']">
            Caracteres máx: 50.
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <sup>(*) Estos campos son obligatorios.</sup>
      </div>


    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <button type="button"  (click)="form.reset()" class="btn btn-info btn-block">Nuevo Registro
        </button>
      </div>
      <div class="form-group col-md-4">
        <button [disabled]="submitted" class="btn-warning btn btn-block">
          <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
          Grabar
        </button>
      </div>
      <div class="form-group col-md-4">
        <button type="button" (click)="form.reset()" class="btn btn-danger btn-block">Cancelar</button>
      </div>
    </div>


  </form>
</div>
