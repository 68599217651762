<div class="row mt-3" [hidden]="!isAuthenticated">
  <div class="col-md-12">
    <h4 class="fecha_dashboard mb-3">{{dashFecha}}</h4>
  </div>
  <div class="col-md-12 col-xl-6">
    <div class="row">
      <div class="col-md-6">
        <div class="bg-info-200 rounded overflow-hidden position-relative text-white mb-g d-flex align-items-center justify-content-center" style="width: 100%; aspect-ratio: 2/1;">
          <div>
            <h2 class="display-4 d-block m-0 text-center">
              <span class="display-3">{{dash.totalCitasHoy}}
              <i class="bi bi-clipboard-check-fill"></i></span>
              <small class="m-0 l-h-n d-block">Total de citas de hoy</small>
            </h2>
          </div>
        </div>
      </div>


      <div class="col-md-6">
        <div class="bg-success-200 rounded overflow-hidden position-relative text-white mb-g d-flex align-items-center justify-content-center" style="width: 100%; aspect-ratio: 2/1;">
          <div class="">
            <h2 class="display-4 d-block m-0 text-center">
              <span class="display-3">{{dash.totalPacientesGlobal}}
                <i class="bi bi-people-fill"></i></span>
              <small class="m-0 l-h-n d-block">Total de pacientes</small>
            </h2>
          </div>
        </div>
      </div>

      <!--      <div class="col-md-6">
        <div class="bg-primary-300 rounded overflow-hidden position-relative text-white mb-g">
          <div class="">
            <h3 class="display-4 d-block m-0  ">
              {{dash.totalPacientesMes}} <i class="bi bi-people-fill"></i>
              <small class="m-0 l-h-n">Nuevos Pacientes del mes</small>
            </h3>
          </div>
        </div>
      </div>-->
      <!--<div class="col-md-6">
        <div class="bg-warning-400 rounded overflow-hidden position-relative text-white mb-g">
          <div class="">
            <h3 class="display-4 d-block m-0  ">
              {{dash.totalPacientesAnio}} <i class="bi bi-people-fill"></i>
              <small class="m-0 l-h-n">Nuevos Pacientes del Año</small>
            </h3>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <div class="col-md-12 col-xl-6">
    <app-pie></app-pie>
  </div>
  <div class="col-md-12">
    <app-line></app-line>
  </div>


</div>

<div class="row mt-3 justify-content-center" [hidden]="isAuthenticated">
  <div class="col-md-6">
    <img src="assets/images/consultorio.png" alt="imagen de unos dientes en un consultorio" class="img-fluid">
  </div>
</div>
