import {Component, OnInit} from '@angular/core';
import {HelperService} from '../service/helper.service';
import {LoginService} from '../service/login.service';
import {UsuarioLogin} from '../model/usuario-login.model';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  isAuthenticated = false;
  UserName = '';


  constructor(private helper: HelperService, private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.helper.data.subscribe(res => {
      this.isAuthenticated = res;
    })

    if (!this.isNullUndefinesOrEmpty(sessionStorage.getItem('jwt'))) {
      this.loginService.getVerificar().subscribe(res => {
      if (res === null) {
        this.isAuthenticated = false;
      } else {
        this.UserName = (res as UsuarioLogin).usuario;
        this.isAuthenticated = true;
        this.helper.actualizarAutenticidad(true);
      }
      });
    } else {
      return null;
    }

  }

  // tslint:disable-next-line:typedef
  cerrarSesion() {
    sessionStorage.clear();
    window.location.reload();
  }

  // tslint:disable-next-line:typedef
  isNullUndefinesOrEmpty(value: string) {
    return value === null || value === undefined || value === '';
  }
}
