<div class="box_login">

  <div class="container">

    <div id="login-row" class="row justify-content-center ">
      <div id="login-column" class="col-md-4 box_Modulo ">
        <div class="login_img"></div>
        <div id="login-box" class="col-md-12 ">
          <form #form="ngForm" (submit)="onSubmit(form)" autocomplete="off">
            <div class="form-group">
              <label class="text_option">Usuario:</label><br>


              <input name="usuario" #usuario="ngModel" [(ngModel)]="service.formData.usuario" class="form-control"
                     required>
            </div>
            <div class="form-group">
              <label class="text_option">Contraseña:</label><br>
              <input type="password" name="contrasenia" #contrasenia="ngModel"
                     [(ngModel)]="service.formData.contrasenia"
                     class="form-control"
                     required>
              <input name="token" #token="ngModel" [(ngModel)]="service.formData.token"
                     class="form-control"
                     hidden>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="submitted" class="btn btn-warning btn-block  btn-md">
                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                Acceder
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
