import {Injectable} from '@angular/core';
import {Paciente} from '../model/paciente.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UsuarioLogin} from '../model/usuario-login.model';
import {NgForm} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formData: UsuarioLogin;

  // tslint:disable-next-line:typedef
  postLogin(formData: UsuarioLogin) {
    return this.http.post(this.rootURL + '/Seguridad/Login', formData);
  }

  // tslint:disable-next-line:typedef
  getVerificar() {
    if (!this.isNullUndefinesOrEmpty(sessionStorage.getItem('jwt'))) {
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('jwt')
      });
      return this.http.get(this.rootURL + '/Seguridad/Verificar', {headers: reqHeader});
    } else {
      return null;
    }

  }

  // tslint:disable-next-line:typedef
  isNullUndefinesOrEmpty(value: string) {
    return value === null || value === undefined || value === '';
  }
}
