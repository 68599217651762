<div class="container-fluid">
  <h1 class="display-4 text-center">Registro de Personal</h1>
  <hr>
  <div class="row">
    <div class="col-md-5">
    <app-personal></app-personal>
    </div>
    <div class="col-md-7">
    <app-personal-lista></app-personal-lista>
    </div>
  </div>
</div>
