import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { Citas } from "../model/citas.model";
import { CitasService } from "../service/citas.service";
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  datos: Citas[];

  constructor(public service: CitasService) {}

  ngOnInit(): void {
    this.dtOptions = {
      paging: false,
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.22/i18n/Spanish.json'
      }
    };

    this.service.listarTodosIndicadores().subscribe(rst => {
      this.datos = rst;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();

      });
    });


  }


  exportToPDF(elementId: string) {
    const dataElement = document.getElementById(elementId);

    if (dataElement) {
      // Ajustar model
      const originalClass = dataElement.className;
      dataElement.className = 'full-page-export';

      const options = {
        margin: [0.0, 1, 0.0, 1],
        filename: `${elementId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      html2pdf().from(dataElement).set(options).save().then(() => {

        dataElement.className = originalClass;
      });
    }
  }

}
