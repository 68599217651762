import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private isAuthenticated = new BehaviorSubject<boolean>(false);
  data = this.isAuthenticated.asObservable();
  constructor() {
  }
  actualizarAutenticidad(valor: boolean){
    this.isAuthenticated.next(valor);
  }
}
