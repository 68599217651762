import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from "rxjs";
import {map} from 'rxjs/operators';
import {Personal} from '../model/personal.model';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  rootURL = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  formData: Personal;
  list: Personal[];

  // tslint:disable-next-line:variable-name
  private _listners = new Subject<any>();


  // tslint:disable-next-line:typedef
  postPersonal(formData: Personal) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.post(this.rootURL + '/Personal/Insertar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  putPersonal(formData: Personal) {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });
    return this.http.put(this.rootURL + '/Personal/Actualizar', formData, {headers: reqHeader});
  }

  // tslint:disable-next-line:typedef
  getAllTodos(): Observable<Personal[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Personal/ListarConEspecialidad', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Personal();
        Object.assign(model, item);
        return model;
      }))
    );
  }



  // tslint:disable-next-line:typedef
  deletePersonal(id: number) {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.delete(this.rootURL + '/Personal/Eliminar?idPersonal=' + id, {headers: reqHeader});
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  // tslint:disable-next-line:typedef
  filter() {
    this._listners.next();
  }

  // tslint:disable-next-line:typedef
  getPersonalPorEspecialidad(idEspecialidad: number): Observable<Personal[]> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    return this.http.get(this.rootURL + '/Personal/ListarPorEspecialidad?idEspecialidad=' +  idEspecialidad, {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new Personal();
        Object.assign(model, item);
        return model;
      }))
    );
  }
}
