<div class="row">
  <div class="col-md-6 mb-2 mb-xl-0">
    <chart [type]="type" [data]="data" [options]="options" style="width: 100%; height: 190px;" class="borde-chart">
    </chart>

  </div>


  <div class="col-md-6  mb-xl-0">
    <chart [type]="type2" [data]="data2" [options]="options2" style="width: 100%; height: 190px;" class="borde-chart">
    </chart>

  </div>
</div>
