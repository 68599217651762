<ng-template #loading>
  <div class="text-center">
    <i class="fas fa-spin fa-spinner fa-5x"></i> <br/>
    Cargando...
  </div>
</ng-template>

<div class="row text-center mt-4 calendario-header">
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-outline-warning"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        Anterior
      </div>
      <div
        class="btn btn-warning"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Hoy
      </div>
      <div
        class="btn btn-outline-warning"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        Siguiente
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>
      {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
    </h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-outline-warning"
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Mes
      </div>
      <div
        class="btn btn-outline-warning"
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Semana
      </div>
      <div
        class="btn btn-outline-warning"
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Dia
      </div>
    </div>
  </div>
</div>
<br/>


<div *ngIf="events$ | async; else loading; let events" class="calendario-body">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="clickedDate = $event.day.date"
      (dayClicked)="triggerModalNuevo(modalData,'D', $event.day.date)"
      (eventClicked)="triggerModalEditar(modalData,'D', $event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [dayStartHour]="horaInicioAtencion"
      [dayEndHour]="horaFinAtencion"
      (hourSegmentClicked)="clickedDate = $event.date"

      (eventClicked)="triggerModalEditar(modalData,'H', $event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [dayStartHour]="horaInicioAtencion"
      [dayEndHour]="horaFinAtencion"
      (hourSegmentClicked)="clickedDate = $event.date"

      (eventClicked)="triggerModalEditar(modalData,'H', $event)"

    >
    </mwl-calendar-day-view>
  </div>
</div>


<ng-template #modalData let-modal>
  <div class="modal-header">
    <h3 class="col-md-10" id="modal-basic-title"><i class="bi bi-journal-text"></i> Registrar Cita</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" class="btn-warning btn">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-buscar-paciente
      (messageEvent)="receiveMessage($event)"
      [item]="esPacienteRegistradoExportar"
    ></app-buscar-paciente>

    <form #formulario="ngForm" name="formulario" (ngSubmit)="formulario.valid && !submitted && onSubmit(formulario)"
          autocomplete="off">
      <div class="form-row boxModulo">
        <div class="col-md-4" hidden>
          <div class="form-check">
            <input class="form-check-input"
                   type="checkbox"
                   [checked]="service.formData.esPacienteRegistrado"
                   name="esPacienteRegistrado"
                   #esPacienteRegistrado="ngModel"
                   [(ngModel)]="service.formData.esPacienteRegistrado"
                   value=""
                   id="esPacienteRegistrado"
            >
          </div>
        </div>

        <div class="form-group col-md-4" hidden>
          <label>Código.</label>
          <input name="idCita" #idCita="ngModel" [(ngModel)]="service.formData.idCita"
                 class="form-control" readonly hidden>
        </div>

        <div class="form-group col-md-4" hidden>
          <label>Id Paciente</label>
          <input name="idPaciente" #idPaciente="ngModel" [(ngModel)]="service.formData.idPaciente"
                 class="form-control" readonly hidden>
        </div>

        <div class="form-group col-md-6">
          <label>Nombres<sup>*</sup></label>
          <input name="nombreCompleto"
                 #nombreCompleto="ngModel"
                 [(ngModel)]="service.formData.nombreCompleto"
                 [readonly]="service.formData.esPacienteRegistrado"
                 class="form-control"
                 required
                 [ngClass]="{ 'is-invalid': formulario.submitted && nombreCompleto.invalid||
                  (nombreCompleto.invalid && (nombreCompleto.dirty || nombreCompleto.touched)),
                  'is-valid':nombreCompleto.valid}"
                 [ngClass]="{ 'is-invalid': formulario.submitted && nombreCompleto.invalid ||
                  (nombreCompleto.invalid && (nombreCompleto.dirty || nombreCompleto.touched)),
                  'is-valid': nombreCompleto.valid }"

                 maxlength="300"
                 [pattern]="patronNombres"
                 oninput="this.value = this.value.toUpperCase();"
          >
          <div *ngIf="nombreCompleto.invalid && (nombreCompleto.dirty || nombreCompleto.touched)"
               class="invalid-feedback">
            <div *ngIf="nombreCompleto.errors?.['required']">
              Campo requerido.
            </div>
            <div *ngIf="nombreCompleto.errors?.['pattern']">
              Escriba solo letras.
            </div>
            <div *ngIf="nombreCompleto.errors?.['maxlength']">
              Caracteres máx: 300.
            </div>
          </div>
        </div>





        <div class="form-group col-md-6">
          <label>Nº Doc.<sup>*</sup></label>
          <input name="documento"
                 #documento="ngModel"
                 [(ngModel)]="service.formData.documento"
                 [readonly]="service.formData.esPacienteRegistrado"
                 [pattern]="patronNumeros"
                 [ngClass]="{ 'is-invalid': formulario.submitted && documento.invalid||
                  (documento.invalid && (documento.dirty || documento.touched)),
                  'is-valid':documento.valid}"
                 minlength="8"
                 maxlength="8"
                 required
                 class="form-control">
          <div *ngIf="documento.invalid && (documento.dirty || documento.touched)"
               class="invalid-feedback">
            <div *ngIf="documento.errors?.['required']">
              Campo requerido.
            </div>
            <div *ngIf="documento.errors?.['minlength']">
              Caracteres mín: 8.
            </div>
            <div *ngIf="celular.errors?.['maxlength']">
              Caracteres máx: 8.
            </div>
            <div *ngIf="documento.errors?.['pattern']">
              Escriba solo números.
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label>Celular<sup>*</sup></label>
          <input name="celular"
                 type="text"
                 #celular="ngModel"
                 [(ngModel)]="service.formData.celular"
                 [readonly]="service.formData.esPacienteRegistrado"
                 [ngClass]="{ 'is-invalid': formulario.submitted && celular.invalid||
                  (celular.invalid && (celular.dirty || celular.touched)),
                  'is-valid':celular.valid}"
                 size="9"
                 minlength="9"
                 maxlength="9"
                 [pattern]="patronNumeros"
                 class="form-control"
                 required>
          <div *ngIf="celular.invalid && (celular.dirty || celular.touched)"
               class="invalid-feedback">
            <div *ngIf="celular.errors?.['required']">
              Campo requerido.
            </div>
            <div *ngIf="celular.errors?.['pattern']">
              Escriba solo numeros.
            </div>
            <div *ngIf="celular.errors?.['minlength']">
              Caracteres mín: 9.
            </div>
            <div *ngIf="celular.errors?.['maxlength']">
              Caracteres máx: 9.
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label>Asunto<sup>*</sup></label>
          <select #asuntoCita="ngModel" name="asuntoCita" [(ngModel)]="service.formData.asuntoCita"
                  required="required" class="form-control"
                  [ngClass]="{ 'is-invalid': formulario.submitted && asuntoCita.value==0 }" required
          >
            <option value=0 class="text-primary font-weight-bolder"></option>
            <option [ngValue]="objeto.id"
                    *ngFor="let objeto of listaConceptoCita">{{ objeto.descripcion }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-6">
          <label>Fecha<sup>*</sup></label>
          <input name="fechaCita"
                 #fechaCita="ngModel"
                 [(ngModel)]="service.formData.fechaCita"
                 class="form-control"
                 #dp="bsDatepicker"
                 bsDatepicker [(bsValue)]="service.formData.fechaCita"
                 value="{{ service.formData.fechaCita | date:'dd/MM/yyyy' }}"
                 required
          >
        </div>
        <div class="form-group col-md-3">
          <label>Hora Inicio<sup>*</sup></label>
          <select #tiempoInicio="ngModel" name="tiempoInicio" [(ngModel)]="service.formData.tiempoInicio"
                  class="form-control" (change)="calcularHora()"
                  [ngClass]="{ 'is-invalid': formulario.submitted && tiempoInicio.value == 0 }" required
          >
            <option value=0 class="text-primary font-weight-bolder"></option>
            <option [ngValue]="objeto.id"
                    *ngFor="let objeto of listadoHoras">{{ objeto.descripcion }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label>Hora Fin<sup>*</sup></label>
          <select #tiempoFin="ngModel" name="tiempoFin" [(ngModel)]="service.formData.tiempoFin"
                  required="required" class="form-control"
                  [ngClass]="{ 'is-invalid': formulario.submitted && tiempoFin.value==0 }" required
          >
            <option value=0 class="text-primary font-weight-bolder"></option>
            <option [ngValue]="objeto.id"
                    *ngFor="let objeto of listadoHoras">{{ objeto.descripcion }}
            </option>
          </select>
        </div>


        <div class="form-group col-md-6">
          <label>Especialidades<sup>*</sup></label>
          <select #idEspecialidad="ngModel" name="idEspecialidad" [(ngModel)]="service.formData.idEspecialidad"
                  required="required" class="form-control" (change)="changeEspecilidad()"
                  [ngClass]="{ 'is-invalid': formulario.submitted && idEspecialidad.value==0 }" required
          >
            <option value=0 class="text-primary font-weight-bolder"></option>
            <option [ngValue]="objeto.idEspecialidad"
                    *ngFor="let objeto of listaEspecialidad">{{ objeto.descripcion }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label>Dr./Dra.<sup>*</sup></label>
          <select #idPersonal="ngModel" name="idPersonal" [(ngModel)]="service.formData.idPersonal"
                  required="required" class="form-control"
                  [ngClass]="{ 'is-invalid': formulario.submitted && idPersonal.value==0 }" required
          >
            <option value=0 class="text-primary font-weight-bolder"></option>
            <option [ngValue]="objeto.idPersonal"
                    *ngFor="let objeto of listaPersonal">{{ objeto.nombres + ' ' + objeto.primerApellido + ' ' + objeto.segundoApellido }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-12">
          <label>Notas de Cita</label>
          <textarea
            name="descripcion"
            #descripcion="ngModel"
            [(ngModel)]="service.formData.descripcion"
            class="form-control"
            rows="3"
            (input)="onDescriptionChange()"
            maxlength="150"
          ></textarea>
          <div class="character-count mt-2">
            <small class="text-muted">{{ characterCount }}/150 caracteres</small>
          </div>
        </div>

      </div>
      <div class="col-md-12">
        <sup>(*) Estos campos son obligatorios.</sup>
      </div>

      <div class="form-group col-md-12 text-center">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input checkbox-lg"
            type="checkbox"
            id="citaAtendida"
            name="citaAtendida"
            [(ngModel)]="service.formData.citaAtendida"
            (change)="toggleCheckbox($event)"
          />
          <label class="form-check-label font-weight-bold text-secondary ml-2" for="citaAtendida"
                 id="citaAtendidaLabel">
            Cita Atendida
          </label>
        </div>
      </div>


      <div class="form-row justify-content-end">

        <div class="form-group col-md-6 ">
          <button [disabled]="submitted " class="btn-warning btn btn-block">
            <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
            Grabar
          </button>
        </div>
      </div>

      <!--      <div *ngIf="formulario.submitted && tiempoInicio.invalid" class="text-danger">-->
      <!--        <h6 *ngIf="tiempoInicio.errors.required">Product is Required</h6>-->
      <!--      </div>-->

    </form>

  </div>
</ng-template>

<div class="row justify-content-center">
  <div class="col-md-6 mt-2 mb-2">
    <ul class="legend">
      <li><span class="dia-actual"></span>Día actual</li>
      <li><span class="cantidad-citas"></span>Cantidad de citas por día</li>
      <li><span class="cita"></span> Cita reservada</li>
    </ul>
  </div>
</div>


<app-busqueda-cita

></app-busqueda-cita>

