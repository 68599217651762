import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PacientesComponent} from './pacientes/pacientes.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {LoginComponent} from './login/login.component';
import {EspecialidadesComponent} from './especialidades/especialidades.component';
import {PersonalgroupComponent} from './personalgroup/personalgroup.component';
import {CitasComponent} from './citas/citas.component';
import {HistoriasClinicasComponent} from './historias-clinicas/historias-clinicas.component';
import {PagosComponent} from './pagos/pagos.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from "./login/AuthGuard";
import {IndicadoresComponent} from "./indicadores/indicadores.component";

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: DashboardComponent},
  {path: 'pacientes', component: PacientesComponent, canActivate: [AuthGuard]},
  {path: 'especialidades', component: EspecialidadesComponent, canActivate: [AuthGuard]},
  {path: 'personal', component: PersonalgroupComponent, canActivate: [AuthGuard]},
  {path: 'citas', component: CitasComponent, canActivate: [AuthGuard]},
  {path: 'historias', component: HistoriasClinicasComponent, canActivate: [AuthGuard]},
  {path: 'pagos', component: PagosComponent, canActivate: [AuthGuard]},
  {path: 'indicadores', component: IndicadoresComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule(
  {
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}), BsDatepickerModule.forRoot()],
    exports: [RouterModule]
  })
export class AppRoutingModule {
}

export const routingComponents = [PacientesComponent, LoginComponent, EspecialidadesComponent, PersonalgroupComponent, CitasComponent, PagosComponent];
