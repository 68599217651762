export class EstadoCivil {

  constructor(idEstadoCivil: number, descripcion: string, eliminado: number) {
    this.idEstadoCivil = idEstadoCivil;
    this.descripcion = descripcion;
    this.eliminado = eliminado;
  }

  idEstadoCivil: number;
  descripcion: string;
  eliminado: number;
}
