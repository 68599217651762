import {Component, ChangeDetectionStrategy, OnInit, ViewChild, TemplateRef, EventEmitter} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format, addHours, addMinutes,
} from 'date-fns';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {Citas} from "../model/citas.model";
import {NgForm} from "@angular/forms";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CitasService} from "../service/citas.service";
import {PersonalService} from "../service/personal.service";
import {ToastrService} from "ngx-toastr";
import {Personal} from "../model/personal.model";
import {EstadoCivil} from "../model/estado-civil.model";
import {colors} from "./colors";
import {Parametrico} from "../model/parametrico.model";
import {Globales} from "../Globales";
import {EspecialidadService} from "../service/especialidad.service";
import {Especialidad} from "../model/especialidad.model";
import {ParametricoService} from "../service/parametrico.service";
import {defineLocale} from "ngx-bootstrap/chronos";
import {esLocale} from "ngx-bootstrap/locale";
import {CitaCalendario} from "../model/cita-calendario.model";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {CustomDateFormatter} from "./custom-date-formatter.provider";
import {PersonaFiltrada} from "../model/personalizado/persona-filtrada.model";



// interface Film {
//   id: number;
//   title: string;
//   release_date: string;
// }

// function getTimezoneOffsetString(date: Date): string {
//   const timezoneOffset = date.getTimezoneOffset();
//   const hoursOffset = String(
//     Math.floor(Math.abs(timezoneOffset / 60))
//   ).padStart(2, '0');
//   const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
//   const direction = timezoneOffset > 0 ? '-' : '+';
//
//   return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
// }

defineLocale('es', esLocale);

@Component({
  selector: 'app-citas',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './citas.component.html',
  styleUrls: ['./citas.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CitasComponent implements OnInit {
  submitted: boolean = false;
  patronNumeros = "^[0-9]*";
  patronNumerosLetras = "^[0-9a-zA-Z]*";
  patronNombres = "^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]*\\s*$";

  esPacienteRegistradoExportar: boolean = false;

  viewChange = new EventEmitter<CalendarView>();
  viewDateChange = new EventEmitter<Date>();
  horaInicioAtencion = Globales.horaInicioAtencion;
  horaFinAtencion = Globales.horaFinAtencion;


  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  clickedDate: Date;
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();
  locale: string = 'es';
  events$: Observable<CalendarEvent<{ cita: Citas }>[]>;

  activeDayIsOpen: boolean = false;
  rootURL = environment.baseUrl;

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  listaPersonal: Personal[];
  listaEspecialidad: Especialidad[];
  listaConceptoCita: Parametrico[];
  closeModal: string;

  listadoHoras: Parametrico[] = [
    {id: 1, descripcion: "12:00 AM"},
    {id: 2, descripcion: "12:15 AM"},
    {id: 3, descripcion: "12:30 AM"},
    {id: 4, descripcion: "12:45 AM"},
    {id: 5, descripcion: "1:00 AM"},
    {id: 6, descripcion: "1:15 AM"},
    {id: 7, descripcion: "1:30 AM"},
    {id: 8, descripcion: "1:45 AM"},
    {id: 9, descripcion: "2:00 AM"},
    {id: 10, descripcion: "2:15 AM"},
    {id: 11, descripcion: "2:30 AM"},
    {id: 12, descripcion: "2:45 AM"},
    {id: 13, descripcion: "3:00 AM"},
    {id: 14, descripcion: "3:15 AM"},
    {id: 15, descripcion: "3:30 AM"},
    {id: 16, descripcion: "3:45 AM"},
    {id: 17, descripcion: "4:00 AM"},
    {id: 18, descripcion: "4:15 AM"},
    {id: 19, descripcion: "4:30 AM"},
    {id: 20, descripcion: "4:45 AM"},
    {id: 21, descripcion: "5:00 AM"},
    {id: 22, descripcion: "5:15 AM"},
    {id: 23, descripcion: "5:30 AM"},
    {id: 24, descripcion: "5:45 AM"},
    {id: 25, descripcion: "6:00 AM"},
    {id: 26, descripcion: "6:15 AM"},
    {id: 27, descripcion: "6:30 AM"},
    {id: 28, descripcion: "6:45 AM"},
    {id: 29, descripcion: "7:00 AM"},
    {id: 30, descripcion: "7:15 AM"},
    {id: 31, descripcion: "7:30 AM"},
    {id: 32, descripcion: "7:45 AM"},
    {id: 33, descripcion: "8:00 AM"},
    {id: 34, descripcion: "8:15 AM"},
    {id: 35, descripcion: "8:30 AM"},
    {id: 36, descripcion: "8:45 AM"},
    {id: 37, descripcion: "9:00 AM"},
    {id: 38, descripcion: "9:15 AM"},
    {id: 39, descripcion: "9:30 AM"},
    {id: 40, descripcion: "9:45 AM"},
    {id: 41, descripcion: "10:00 AM"},
    {id: 42, descripcion: "10:15 AM"},
    {id: 43, descripcion: "10:30 AM"},
    {id: 44, descripcion: "10:45 AM"},
    {id: 45, descripcion: "11:00 AM"},
    {id: 46, descripcion: "11:15 AM"},
    {id: 47, descripcion: "11:30 AM"},
    {id: 48, descripcion: "11:45 AM"},
    {id: 49, descripcion: "12:00 PM"},
    {id: 50, descripcion: "12:15 PM"},
    {id: 51, descripcion: "12:30 PM"},
    {id: 52, descripcion: "12:45 PM"},
    {id: 53, descripcion: "1:00 PM"},
    {id: 54, descripcion: "1:15 PM"},
    {id: 55, descripcion: "1:30 PM"},
    {id: 56, descripcion: "1:45 PM"},
    {id: 57, descripcion: "2:00 PM"},
    {id: 58, descripcion: "2:15 PM"},
    {id: 59, descripcion: "2:30 PM"},
    {id: 60, descripcion: "2:45 PM"},
    {id: 61, descripcion: "3:00 PM"},
    {id: 62, descripcion: "3:15 PM"},
    {id: 63, descripcion: "3:30 PM"},
    {id: 64, descripcion: "3:45 PM"},
    {id: 65, descripcion: "4:00 PM"},
    {id: 66, descripcion: "4:15 PM"},
    {id: 67, descripcion: "4:30 PM"},
    {id: 68, descripcion: "4:45 PM"},
    {id: 69, descripcion: "5:00 PM"},
    {id: 70, descripcion: "5:15 PM"},
    {id: 71, descripcion: "5:30 PM"},
    {id: 72, descripcion: "5:45 PM"},
    {id: 73, descripcion: "6:00 PM"},
    {id: 74, descripcion: "6:15 PM"},
    {id: 75, descripcion: "6:30 PM"},
    {id: 76, descripcion: "6:45 PM"},
    {id: 77, descripcion: "7:00 PM"},
    {id: 78, descripcion: "7:15 PM"},
    {id: 79, descripcion: "7:30 PM"},
    {id: 80, descripcion: "7:45 PM"},
    {id: 81, descripcion: "8:00 PM"},
    {id: 82, descripcion: "8:15 PM"},
    {id: 83, descripcion: "8:30 PM"},
    {id: 84, descripcion: "8:45 PM"},
    {id: 85, descripcion: "9:00 PM"},
    {id: 86, descripcion: "9:15 PM"},
    {id: 87, descripcion: "9:30 PM"},
    {id: 88, descripcion: "9:45 PM"},
    {id: 89, descripcion: "10:00 PM"},
    {id: 90, descripcion: "10:15 PM"},
    {id: 91, descripcion: "10:30 PM"},
    {id: 92, descripcion: "10:45 PM"},
    {id: 93, descripcion: "11:00 PM"},
    {id: 94, descripcion: "11:15 PM"},
    {id: 95, descripcion: "11:30 PM"},
    {id: 96, descripcion: "11:45 PM"}
  ];

  private personaFiltradaHeredada: PersonaFiltrada;
  private validacionForm: boolean;

  // private esPacienteRegistradoHeredado: boolean;


  constructor(private http: HttpClient, private modalService: NgbModal,
              public service: CitasService,
              public servicePersonal: PersonalService,
              public serviceEspecialidad: EspecialidadService,
              public serviceParametrico: ParametricoService,
              private bsLocaleService: BsLocaleService,
              private toastr: ToastrService) {
    this.bsLocaleService.use('es');
    this.listadoHoras = this.listadoHoras.filter(
      obj => obj.id >= this.horaInicioAtencion * 4 + 1 && obj.id <= this.horaFinAtencion * 4 + 1);
  }

  ngOnInit(): void {

    this.fetchEvents();
    this.resetForm();
    this.getEspecialidades();
    this.getConceptosCita();
  }

  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('jwt')
    });

    this.events$ = this.http.get(this.rootURL + '/Cita/ListarCalendario', {headers: reqHeader}).pipe(
      map((data: any[]) => data.map((item: any) => {
        const model = new CitaCalendario();
        Object.assign(model, item);

        // Obtener las horas de inicio y fin
        let tiempoInicio = this.listadoHoras.find(x => x.id === item.tiempoInicio);
        let tiempoFin = this.listadoHoras.find(x => x.id === item.tiempoFin);

        // Parsear las descripciones para obtener horas y minutos
        var arrayTiempoInicio = tiempoInicio.descripcion.split(" ", 2); // Separa la hora y el AM/PM
        var arrayTiempoFin = tiempoFin.descripcion.split(" ", 2); // Separa la hora y el AM/PM

        let [horaInicio, periodoInicio] = arrayTiempoInicio;
        let [horaFin, periodoFin] = arrayTiempoFin;

        // Conversion a formato de 24 horas
        let agregarHoraInicio: number = +horaInicio.split(":")[0];
        let agregarMinutoInicio: number = +horaInicio.split(":")[1];
        let agregarHoraFin: number = +horaFin.split(":")[0];
        let agregarMinutoFin: number = +horaFin.split(":")[1];

        if (periodoInicio === "PM" && agregarHoraInicio !== 12) agregarHoraInicio += 12;
        if (periodoInicio === "AM" && agregarHoraInicio === 12) agregarHoraInicio = 0;
        if (periodoFin === "PM" && agregarHoraFin !== 12) agregarHoraFin += 12;
        if (periodoFin === "AM" && agregarHoraFin === 12) agregarHoraFin = 0;

        return {
          title: '👤'+ item.nombreCompleto+  '<br>'+'🦷' + item.nombreEspecialidad +'➖'+item.titulo,
          start: addMinutes(addHours(new Date(item.fechaCita), agregarHoraInicio), agregarMinutoInicio),
          end: addMinutes(addHours(new Date(item.fechaCita), agregarHoraFin), agregarMinutoFin),

          color: item.citaAtendida === 1 ? colors.atendida : colors.noAtendida,
          idCita: item.idCita,
          cssClass: 'evento-cita',
        };
      }))


  );


  }

  // dayClicked({date, events,}: {
  //   date: Date;
  //   events: CalendarEvent<{ film: Film }>[];
  // }): void {
  //   if (isSameMonth(date, this.viewDate)) {
  //     if (
  //       (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
  //       events.length === 0
  //     ) {
  //       this.activeDayIsOpen = false;
  //     } else {
  //       this.activeDayIsOpen = true;
  //       this.viewDate = date;
  //     }
  //   }
  // }

  // eventClicked(event: CalendarEvent<{ film: Film }>): void {
  //   window.open(
  //     `https://www.themoviedb.org/movie/${event.meta.film.id}`,
  //     '_blank'
  //   );
  // }


  resetForm(form?: NgForm) {
    this.submitted = false;
    if (form != null) {
      form.resetForm();
    }
    this.service.formData = {
      idCita: 0,
      idPaciente: 0,
      asuntoCita: 0,
      idPersonal: 0,
      idEspecialidad: 0,
      fechaCita: null,
      tiempoInicio: 0,
      tiempoFin: 0,
      esPacienteRegistrado: false,
      nombreCompleto: '',
      documento: '',
      celular: '',
      descripcion: '',
      fechaReprogramada:null,
      citaAtendida:false,
      citaReprogramada:false
    };
  }

  private getEspecialidades() {
    this.serviceEspecialidad.getAllTodos().subscribe(
      res => this.listaEspecialidad = res
    )
  }

  // tslint:disable-next-line:typedef
  private getPersonalPorEspecilidad(idEspecialidad: number) {
    this.servicePersonal.getPersonalPorEspecialidad(idEspecialidad).subscribe(
      res => this.listaPersonal = res
    );
  }

  // tslint:disable-next-line:typedef

  onSubmit(form: NgForm) {

    if (this.service.formData.tiempoInicio == 0
      || this.service.formData.tiempoFin == 0
      || this.service.formData.asuntoCita == 0
      || this.service.formData.idEspecialidad == 0
      || this.service.formData.idPersonal == 0
    ) {
      return false;
    }
    if (!this.service.formData.esPacienteRegistrado) {
      this.service.formData.idPaciente = null;
    }
    this.submitted = true;
    if (form.value.idCita == null || form.value.idCita === 0) {
      this.insertRecord(form);
    } else {
      this.updateRecord(form);
    }

  }

  resolveAfter2Seconds(x) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, 1000);
    });
  }

  // tslint:disable-next-line:typedef
  insertRecord(form: NgForm) {
    this.service.postCitas(form.value).subscribe(res => {
      this.resolveAfter2Seconds(20).then(value => {

        if(res.idRespuesta==200)
        {
          this.fetchEvents();
          this.toastr.success('Programado correctamente', 'CITA');
          this.resetForm(form);
          this.modalService.dismissAll();
        }
        else {
          this.submitted = false;
          this.toastr.warning(res.mensaje, 'CITA');
          return 0;
        }
      });
    });
  }

  // tslint:disable-next-line:typedef
  updateRecord(form: NgForm) {
    this.service.putCitas(form.value).subscribe(res => {
      this.resolveAfter2Seconds(20).then(value => {
        if(res.idRespuesta==200)
        {
          this.fetchEvents();
          this.toastr.info('actualizado correctamente', 'CITA');
          this.resetForm(form);
          this.modalService.dismissAll();
        }
        else {
          this.submitted = false;
          this.toastr.warning(res.mensaje, 'CITA');
          return 0;
        }
      });

      //this.service.filter();
    });
  }

  calcularHora() {
    if (this.service.formData.tiempoInicio > 0) {
      this.service.formData.tiempoFin = this.service.formData.tiempoInicio + 4;
    }

  }

  // tslint:disable-next-line:typedef
  triggerModalNuevo(content, tipo, event) {
    this.resetForm();
    this.service.formData.tiempoInicio = 0;
    this.esPacienteRegistradoExportar = false;
    this.service.formData.fechaCita = new Date(event);

    //let fechaActualSistema = new Date();

    // let anioSistema = fechaActualSistema.getFullYear();
    // let mesSistema = fechaActualSistema.getMonth();
    // let diaSistema = fechaActualSistema.getDay();
    // let horaSistema = fechaActualSistema.getHours();
    // let minutoSistema = fechaActualSistema.getMinutes();
    //
    //let minutoClick = event.getMinutes();
    //let horaClick = event.getHours();
    //this.service.formData.tiempoFin = null;
    //
    // if (minutoClick > 45) {
    //   horaClick = horaClick + 1;
    //   this.service.formData.tiempoInicio = (horaClick * 4 + 1).toString();
    //   minutoClick = 0;
    // } else if (minutoClick > 30) {
    //   minutoClick = 45;
    //   this.service.formData.tiempoInicio = (horaClick * 4 + 4).toString();
    // } else if (minutoClick > 15) {
    //   minutoClick = 30;
    //   let calculado = horaClick * 4 + 3;
    //   this.listadoHoras = this.listadoHoras.filter(obj => obj.id >= calculado);
    //   this.service.formData.tiempoInicio = calculado.toString();
    //
    // } else {
    //   minutoClick = 15;
    //this.service.formData.tiempoInicio = horaClick * 4 + 2;
    // }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  // tslint:disable-next-line:typedef

  triggerModalEditar(content, tipo, event: any) {
    this.resetForm();
    this.esPacienteRegistradoExportar = false;


    this.service.getCita(event.event.idCita).subscribe(
      res => {
        this.getEspecialidades();
        this.listaPersonal = null;
        res.fechaCita = new Date(res.fechaCita);
        this.service.formData = res;
        this.getPersonalPorEspecilidad(res.idEspecialidad);
        this.esPacienteRegistradoExportar = this.service.formData.esPacienteRegistrado;
      }
    )


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }

  setView(view: CalendarView) {
    this.view = view;
  }


  changeEspecilidad() {
    this.getPersonalPorEspecilidad(this.service.formData.idEspecialidad);
  }

  private getConceptosCita() {
    this.serviceParametrico.getListaConceptosCita().subscribe(res => {
      this.listaConceptoCita = res;
    });
  }

  receiveMessage($event: PersonaFiltrada) {
    this.personaFiltradaHeredada = $event;
    this.service.formData.esPacienteRegistrado = this.personaFiltradaHeredada.esPacienteRegistrado;
    this.service.formData.idPaciente = this.personaFiltradaHeredada.idPaciente;
    this.service.formData.nombreCompleto = this.personaFiltradaHeredada.nombreCompleto;
    this.service.formData.documento = this.personaFiltradaHeredada.documento;
    this.service.formData.celular = this.personaFiltradaHeredada.celular;
  }

  characterCount: number = 0;


  onDescriptionChange() {
    // Contar los caracteres
    this.characterCount = this.service.formData.descripcion.length;
  }

  toggleCheckbox(event: any) {
    const label = document.getElementById('citaAtendidaLabel');
    if (event.target.checked) {
      event.target.classList.add('checked-green');
      label?.classList.add('text-green');
    } else {
      event.target.classList.remove('checked-green');
      label?.classList.remove('text-green');
    }
  }



}
